import * as React from 'react'
import { Button, Dialog, DialogContent, DialogActions, Slide, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import BootstrapDialogTitle from './DialogTitle'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ModalDialog = (props) => {
  const{isHeader}=props
  const handleClose = (event, reason) => {
    event.preventDefault()
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      props.handleClose()
    }
  }

  const handleAction = (event) => {
    event.preventDefault()
    props.handleAction()
    // props.handleClose()
  }
  return (
    <BootstrapDialog
      TransitionComponent={Transition}
      data-testid='mui-dialog'
      onClose={props.isDialogReq ? handleClose : props.handleClose}
      aria-labelledby='customized-dialog-title'
      open={props.open}
      fullScreen={props.fullScreen}
      fullWidth={props.fullWidth || false}
      maxWidth={props.maxWidth || 'xs'}
      PaperProps={{
        sx: {
          minHeight: props.minHeight
        }
      }}
    >
      <BootstrapDialogTitle className={isHeader?"header-color":""}  id='customized-dialog-title' onClose={props.isDialogReq ? handleClose : props.handleClose}>
        {props.title}
        {props.subtitle && (
          <div
            style={{ fontSize: '0.8rem', color: 'darkgrey', lineHeight: '0.7rem', fontWeight: 500, marginTop: '5px' }}
          >
            {props.subtitle}
          </div>
        )}
      </BootstrapDialogTitle>
      {props.isDivider && <Divider variant='middle' />}
      <DialogContent>{props.content}</DialogContent>
      {props.handleAction !== undefined && (
        <DialogActions>
          <Button onClick={handleAction} data-testId='action-button'>
            {props.actionTitle}
          </Button>
        </DialogActions>
      )}
      {props.buttons !== undefined && (
        <DialogActions>
          <Button onClick={props.handleClose || props.handleCancel}>Cancel</Button>
          <Button color={props.type} onClick={props.handleOK}>
            Save
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  )
}

ModalDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  actionTitle: PropTypes.string,
  handleAction: PropTypes.func,
  handleCancel: PropTypes.func,
  isDialogReq: PropTypes.bool,
  type: PropTypes.string,
  handleOK: PropTypes.func,
  buttons: PropTypes.bool,
  minHeight: PropTypes.string,
  subtitle: PropTypes.string,
  isDivider: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isHeader:PropTypes.bool,
}

export default ModalDialog
