import React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import dashboardRoutes from 'routes/DashboardRoutes'
import userRoutes from 'routes/UserRoutes'
import openRoutes from 'routes/OpenRoutes'
import Loader from 'components/common/Loader'
import authService from 'shared/services/auth.service'
import 'assets/scss/style.scss'
import { QueryClient, QueryClientProvider } from 'react-query'
import userService from 'shared/services/user-service'
import { Provider } from 'react-redux'
import store from './redux/store/store'
import { UserContext } from 'context/AuthContext/UserContext'
const DashboardLayout = React.lazy(() => import('layout/dashboard/DashboardLayout'))
const UserLayout = React.lazy(() => import('layout/user/UserLayout'))
const PageNotFound = React.lazy(() => import('pages/PageNotFound'))
const queryClient = new QueryClient()

function App() {
  const navigate = useNavigate()
  const currentUser = userService.getCurrentUser()?.user
  const contextObj = React.useContext(UserContext)

  const filterRoutesByUserType = (routes, pomUserType, userRole) => {
    return routes.filter(
      (route) =>
        route?.pomUserType?.includes(pomUserType) &&
        userRole.some(
          (accessRole) => accessRole.role >= route?.roleCode?.role && accessRole.module == route?.roleCode?.module
        )
    )
  }
  const filteredRoutes = filterRoutesByUserType(
    dashboardRoutes,
    currentUser?.pomUserType,
    contextObj?.userRole?.userSubscription?.roleCode || currentUser?.role
  )
  const logOut = () => {
    authService.logout()
    navigate('/login', { state: 'You Have Been Successfully Logged out of the system' })
  }
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            {userService.isLoggedIn() && (
              <Route element={<DashboardLayout currentUser={currentUser} logOut={logOut} />}>
                <Route path='/' element={<Navigate to={filteredRoutes[0]?.path} replace />} />
                {filteredRoutes.map((route, idx) => {
                  return (
                    <Route
                      key={idx}
                      index={route.isIndex}
                      path={route?.path}
                      name={route?.name}
                      element={route?.component}
                    />
                  )
                })}
              </Route>
            )}
            <Route element={<UserLayout currentUser={currentUser} loggedOutUser />}>
              {userRoutes.map((route, idx) => {
                return <Route key={idx} path={route.path} name={route.name} element={route.component} />
              })}
            </Route>
            <Route element={<UserLayout openRoute />}>
              {openRoutes.map((route, idx) => {
                return <Route key={idx} path={route.path} name={route.name} element={route.component} />
              })}
            </Route>
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </React.Suspense>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
