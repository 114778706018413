import React from 'react'
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button,
  Tooltip,
  Typography,
  Stack,
  Chip,
  Collapse
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { GrPowerReset } from 'react-icons/gr'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import CustomisedInput from 'components/formElements/CustomisedInput'

const DrawerListAnalytices = ({
  sku,
  onChangeFilter,
  onChangeRouter,
  getMaxDateTwoDaysBefore,
  selectedYear,
  selectedOption,
  setSelectedOption,
  setPage,
  setClickedRows,
  multipleShopNames,
  setMultipleShopNames,
  groupedShops,
  handlePrefixToggle,
  expandedPrefix,
  shipment,
  selectedShipmentCountry,
  setSelectedShipmentCountry,
  handleResetFilters,
  //sku select options
  skuSelectedOption,
  setSkuSelectedOption,
  setSkuPage,
  selectedShop,
  setSelectedShop,
  selectedShipmentCountrySku,
  setSelectedShipmentCountrySku,
  selectedPercent,
  setSelectedPercent,
  wareHouse,
  selectedSkuFilter,
  setSelectedSkuFilter
}) => {
  const { newParam } = useContext(UserContext)

  return (
    <Box
      sx={{
        width: 300,
        padding: '20px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#FFF',
        borderRadius: '10px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#b9b9b9',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#b9b9b9',
          borderRadius: '10px',
          border: '2px solid #f5f5f5'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
          borderRadius: '10px'
        },
        '& *': {
          scrollbarWidth: 'thin',
          scrollbarColor: '#b9b9b9 #f5f5f5'
        }
      }}
    >
      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: '20px',
          fontSize: '1.2rem',
          color: '#333'
        }}
      >
        {sku ? 'Sku' : 'Shops'} Apply Filters
      </Typography>
      <Box sx={{ flex: 1, overflowY: 'auto', marginBottom: '20px', paddingRight: '16px' }}>
        {sku ? (
          <Grid container direction='column' spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return `Select Year`
                    }
                    return selected
                  }}
                  IconComponent={() => null}
                  value={skuSelectedOption}
                  disabled={
                    (newParam.has('sku_start_date') && newParam.has('sku_end_date')) || newParam.has('selected_year')
                  }
                  onChange={(event) => {
                    setSkuPage(0), setSkuSelectedOption(event.target.value)
                  }}
                >
                  <MenuItem value='weekToDate'>Week to Date</MenuItem>
                  <MenuItem value='monthToDate'>Month to Date</MenuItem>
                  <MenuItem value='yearToDate' disabled={selectedYear === 'currentYear'}>
                    Year to Date
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <TextField
                  type='date'
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  IconComponent={() => null}
                  value={
                    newParam.get('sku_start_date')
                      ? new Date(newParam.get('sku_start_date')).toISOString().split('T')[0]
                      : newParam.get('sku_start_date')
                  }
                  onChange={(e) => onChangeFilter('sku_start_date', e.target.value)}
                  max={getMaxDateTwoDaysBefore()}
                  disabled={false}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <TextField
                  type='date'
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  IconComponent={() => null}
                  value={
                    newParam.get('sku_end_date')
                      ? new Date(newParam.get('sku_end_date')).toISOString().split('T')[0]
                      : newParam.get('sku_end_date')
                  }
                  disabled={!newParam.get('sku_start_date')}
                  onChange={(e) => onChangeFilter('sku_end_date', e.target.value)}
                  min={new Date(newParam.get('sku_start_date')).toISOString().split('T')[0]}
                  max={getMaxDateTwoDaysBefore()}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <Tooltip title={'Search Sku'}>
                <FormControl
                  fullWidth
                  sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                  size='small'
                  className='border-none'
                >
                  <TextField
                    placeholder='Search Sku'
                    size='small'
                    variant='outlined'
                    InputLabelProps={{
                      shrink: false
                    }}
                    value={newParam.get('search_sku') || ''}
                    input={<CustomisedInput />}
                    onChange={(e) => {
                      onChangeRouter('search_sku', e?.target?.value.trim()), setSkuPage(0)
                    }}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                <Select
                  value={selectedShop || ''}
                  displayEmpty
                  onChange={(e) => {
                    setSkuPage(0), setSelectedShop(e.target.value)
                  }}
                  renderValue={(selected) => {
                    if (!selected) return 'All Shops'
                    const foundShop = Object.values(groupedShops)
                      .flat()
                      .find((shop) => shop === selected)
                    return foundShop || selected
                  }}
                  sx={{
                    '& .MuiSelect-select': { padding: '10px 12px' },
                    color: '#333'
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 400
                      }
                    }
                  }}
                >
                  <MenuItem value=''>All Shops</MenuItem>
                  {Object.keys(groupedShops).map((prefix) => {
                    const shops = groupedShops[prefix]
                    if (shops.length === 1) {
                      return (
                        <MenuItem
                          key={shops[0]}
                          value={shops[0] || ''}
                          selected={selectedShop === shops[0]}
                          onClick={() => {
                            setSkuPage(0), setSelectedShop(shops[0])
                          }}
                        >
                          {shops[0]}
                        </MenuItem>
                      )
                    } else {
                      return (
                        <React.Fragment key={prefix}>
                          <MenuItem
                            onClick={() => handlePrefixToggle(prefix)}
                            sx={{ fontWeight: 'bold', justifyContent: 'space-between' }}
                          >
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {prefix === 'Mark' ? 'Mark & Day' : prefix}
                            </Typography>
                            <ExpandMoreIcon
                              sx={{
                                transition: 'transform 0.2s',
                                transform: expandedPrefix === prefix ? 'rotate(180deg)' : 'rotate(0deg)'
                              }}
                            />
                          </MenuItem>
                          <Collapse in={expandedPrefix === prefix} timeout='auto' unmountOnExit>
                            {shops.map((shop) => (
                              <MenuItem
                                key={shop}
                                value={shop}
                                sx={{ pl: 4 }}
                                selected={selectedShop === shop}
                                onClick={() => {
                                  setSkuPage(0), setSelectedShop(shop)
                                }}
                              >
                                {shop}
                              </MenuItem>
                            ))}
                          </Collapse>
                        </React.Fragment>
                      )
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={selectedShipmentCountrySku || ''}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Shipment Country'
                    }
                    return selected
                  }}
                  sx={{
                    '& .MuiSelect-select': { padding: '10px 12px' },
                    color: '#333'
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 400,
                        maxWidth: 200
                      }
                    }
                  }}
                  IconComponent={() => null}
                  onChange={(e) => {
                    setSkuPage(0), setSelectedShipmentCountrySku(e.target.value)
                  }}
                >
                  <MenuItem value=''>All Country</MenuItem>
                  {shipment.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Tooltip title={item}>{item}</Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={selectedPercent || ''}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Range'
                    }
                    return selected
                  }}
                  IconComponent={() => null}
                  onChange={(event) => {
                    setSkuPage(0), setSelectedPercent(event.target.value)
                  }}
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='mostDecreased'>Most Decreased</MenuItem>
                  <MenuItem value='mostIncreased'>Most Increased</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={newParam.get('warehouse') || ''}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'All Warehouse'
                    }
                    return selected
                  }}
                  IconComponent={() => null}
                  onChange={(e) => {
                    setSkuPage(0), onChangeRouter('warehouse', e.target.value)
                  }}
                >
                  <MenuItem value=''>All Warehouse</MenuItem>
                  {Array.isArray(wareHouse) &&
                    wareHouse.length &&
                    wareHouse.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={selectedSkuFilter === 'All' ? 'Warehouse Discontinued Products' : selectedSkuFilter}
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  onChange={(e) => {
                    setSelectedSkuFilter(e.target.value)
                  }}
                  IconComponent={() => null}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return `SKU Discontinued Filter`
                    }
                    return selected
                  }}
                >
                  <MenuItem value='All'>All</MenuItem>
                  {Array.isArray(wareHouse) &&
                    wareHouse.length &&
                    wareHouse.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={newParam.get('commerce_type_sku') || ''}
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Commerce Type'
                    }
                    const menuItems = [
                      { value: 'ecomm', label: 'Ecomm' },
                      { value: 'false', label: 'Non Ecomm' }
                    ]

                    const selectedItem = menuItems.find((item) => item.value === selected)
                    return selectedItem ? selectedItem.label : 'Commerce Type'
                  }}
                  onChange={(e) => {
                    setSkuPage(0), onChangeRouter('commerce_type_sku', e.target.value)
                  }}
                  IconComponent={() => null}
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='ecomm'>Ecomm</MenuItem>
                  <MenuItem value='false'>Non Ecomm</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={newParam.get('sku_shop_type') || ''}
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Shop Type'
                    }
                    const menuItems = [
                      { value: 'B2B', label: 'B2B' },
                      { value: 'B2C', label: 'B2C' }
                    ]

                    const selectedItem = menuItems.find((item) => item.value === selected)
                    return selectedItem ? selectedItem.label : 'Shop Type'
                  }}
                  onChange={(e) => {
                    onChangeRouter('sku_shop_type', e?.target?.value.trim()), setSkuPage(0)
                  }}
                  IconComponent={() => null}
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='B2B'>B2B</MenuItem>
                  <MenuItem value='B2C'>B2C</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction='column' spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return `Select Year`
                    }
                    return selected
                  }}
                  IconComponent={() => null}
                  value={selectedOption}
                  disabled={(newParam.has('start_date') && newParam.has('end_date')) || newParam.has('selected_year')}
                  onChange={(event) => {
                    setPage(0), setClickedRows({}), setSelectedOption(event.target.value)
                  }}
                >
                  <MenuItem value='weekToDate'>Week to Date</MenuItem>
                  <MenuItem value='monthToDate'>Month to Date</MenuItem>
                  <MenuItem value='yearToDate' disabled={selectedYear === 'currentYear'}>
                    Year to Date
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <TextField
                  type='date'
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  IconComponent={() => null}
                  value={
                    newParam.get('start_date')
                      ? new Date(newParam.get('start_date')).toISOString().split('T')[0]
                      : newParam.get('start_date')
                  }
                  onChange={(e) => onChangeFilter('start_date', e.target.value)}
                  max={getMaxDateTwoDaysBefore()}
                  disabled={false}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <TextField
                  type='date'
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  IconComponent={() => null}
                  value={
                    newParam.get('end_date')
                      ? new Date(newParam.get('end_date')).toISOString().split('T')[0]
                      : newParam.get('end_date')
                  }
                  disabled={!newParam.get('start_date')}
                  onChange={(e) => onChangeFilter('end_date', e.target.value)}
                  min={new Date(newParam.get('start_date')).toISOString().split('T')[0]}
                  max={getMaxDateTwoDaysBefore()}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <Tooltip title={'Search Shop'}>
                <FormControl
                  fullWidth
                  sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                  size='small'
                  className='border-none'
                >
                  <TextField
                    placeholder='Search Shop'
                    size='small'
                    variant='outlined'
                    InputLabelProps={{
                      shrink: false
                    }}
                    value={newParam.get('search_shop') || ''}
                    input={<CustomisedInput />}
                    onChange={(e) => {
                      onChangeRouter('search_shop', e?.target?.value.trim()), setPage(0)
                    }}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  multiple
                  displayEmpty
                  IconComponent={() => null}
                  value={multipleShopNames}
                  onChange={(e) => {
                    const value = e.target.value
                    if (value.includes('all')) {
                      setPage(0)
                      setMultipleShopNames([])
                    } else {
                      setPage(0)
                      setMultipleShopNames(value)
                    }
                  }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return 'Select Multiple Shop'
                    }
                    const displayCount = 1
                    const hiddenCount = selected.length - displayCount
                    return (
                      <Stack gap={1} direction='row' flexWrap='wrap'>
                        {selected.slice(0, displayCount).map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() => setMultipleShopNames(multipleShopNames.filter((item) => item !== value))}
                            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          />
                        ))}
                        {hiddenCount > 0 && <Chip label={`+${hiddenCount} more`} />}
                      </Stack>
                    )
                  }}
                  sx={{
                    '& .MuiSelect-select': { padding: '10px 12px' },
                    color: '#333'
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 400
                      }
                    }
                  }}
                >
                  <MenuItem value='all'>All Shops</MenuItem>
                  {Object.keys(groupedShops).map((prefix) => {
                    const shops = groupedShops[prefix]
                    if (shops.length === 1) {
                      return (
                        <MenuItem key={shops[0]} value={shops[0]}>
                          {shops[0]}
                          {multipleShopNames.includes(shops[0]) ? <CheckIcon className='ms-1' color='info' /> : null}
                        </MenuItem>
                      )
                    } else {
                      return (
                        <React.Fragment key={prefix}>
                          <MenuItem
                            onClick={() => handlePrefixToggle(prefix)}
                            sx={{ fontWeight: 'bold', justifyContent: 'space-between' }}
                          >
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {prefix === 'Mark' ? 'Mark & Day' : prefix}
                            </Typography>
                            <ExpandMoreIcon
                              sx={{
                                transition: 'transform 0.2s',
                                transform: expandedPrefix === prefix ? 'rotate(180deg)' : 'rotate(0deg)'
                              }}
                            />
                          </MenuItem>
                          <Collapse in={expandedPrefix === prefix} timeout='auto' unmountOnExit>
                            <MenuItem
                              className='fw-semibold'
                              key={prefix}
                              value={prefix}
                              sx={{ pl: 4 }}
                              selected={newParam.get('shops_overall') === prefix}
                              onClick={() => {
                                const newSelectedShops = [...multipleShopNames]
                                const allShopsSelected = shops.every((shop) => newSelectedShops.includes(shop))
                                if (allShopsSelected) {
                                  setMultipleShopNames(newSelectedShops.filter((shop) => !shops.includes(shop)))
                                } else {
                                  const allShops = [
                                    ...newSelectedShops,
                                    ...shops.filter((shop) => !newSelectedShops.includes(shop))
                                  ]
                                  setMultipleShopNames(allShops)
                                }
                              }}
                            >
                              {prefix === 'Mark' ? 'Mark & Day' : prefix}
                            </MenuItem>
                            {shops.map((shop) => (
                              <MenuItem
                                key={shop}
                                value={shop}
                                sx={{ pl: 4 }}
                                onClick={() => {
                                  const newSelectedShops = [...multipleShopNames]
                                  if (newSelectedShops.includes(shop)) {
                                    setMultipleShopNames(newSelectedShops.filter((item) => item !== shop))
                                  } else {
                                    setMultipleShopNames([...newSelectedShops, shop])
                                  }
                                }}
                              >
                                {shop}
                                {multipleShopNames.includes(shop) ? <CheckIcon className='ms-1' color='info' /> : null}
                              </MenuItem>
                            ))}
                          </Collapse>
                        </React.Fragment>
                      )
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={selectedShipmentCountry}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Shipment Country'
                    }
                    return selected
                  }}
                  sx={{
                    '& .MuiSelect-select': { padding: '10px 12px' },
                    color: '#333'
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 400,
                        maxWidth: 200
                      }
                    }
                  }}
                  IconComponent={() => null}
                  onChange={(e) => {
                    setPage(0), setClickedRows({}), setSelectedShipmentCountry(e.target.value)
                  }}
                >
                  <MenuItem value=''>All Country</MenuItem>
                  {shipment.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Tooltip title={item}>{item}</Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={newParam.get('commerce_type_shop') || ''}
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Commerce Type'
                    }
                    const menuItems = [
                      { value: 'ecomm', label: 'Ecomm' },
                      { value: 'false', label: 'Non Ecomm' }
                    ]

                    const selectedItem = menuItems.find((item) => item.value === selected)
                    return selectedItem ? selectedItem.label : 'Commerce Type'
                  }}
                  onChange={(e) => {
                    onChangeRouter('commerce_type_shop', e?.target?.value.trim()), setPage(0)
                  }}
                  IconComponent={() => null}
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='ecomm'>Ecomm</MenuItem>
                  <MenuItem value='false'>Non Ecomm</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl
                fullWidth
                sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                size='small'
                className='border-none'
              >
                <Select
                  value={newParam.get('shop_type') || ''}
                  displayEmpty
                  InputLabelProps={{ shrink: false }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Shop Type'
                    }
                    const menuItems = [
                      { value: 'B2B', label: 'B2B' },
                      { value: 'B2C', label: 'B2C' }
                    ]

                    const selectedItem = menuItems.find((item) => item.value === selected)
                    return selectedItem ? selectedItem.label : 'Shop Type'
                  }}
                  onChange={(e) => {
                    onChangeRouter('shop_type', e?.target?.value.trim()), setPage(0)
                  }}
                  IconComponent={() => null}
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='B2B'>B2B</MenuItem>
                  <MenuItem value='B2C'>B2C</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Box>
      <Button
        variant='contained'
        onClick={() => {
          if (sku) {
            handleResetFilters('SKU')
          } else {
            handleResetFilters('SHOPS')
          }
        }}
        sx={{
          backgroundColor: '#424242',
          color: 'white',
          padding: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#333'
          }
        }}
        startIcon={<GrPowerReset size='1.0rem' />}
      >
        Reset Filters
      </Button>
    </Box>
  )
}

DrawerListAnalytices.propTypes = {
  sku: PropTypes.bool.isRequired,
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeRouter: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setClickedRows: PropTypes.func.isRequired,
  getMaxDateTwoDaysBefore: PropTypes.func.isRequired,
  selectedShopNames: PropTypes.array.isRequired,
  setMultipleShopNames: PropTypes.func.isRequired,
  shops: PropTypes.array.isRequired,
  shipment: PropTypes.array.isRequired,
  exportData: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
  expandedPrefix: PropTypes.bool.isRequired,
  groupedShops: PropTypes.array.isRequired,
  handlePrefixToggle: PropTypes.func.isRequired,
  selectedYear: PropTypes.string.isRequired,
  multipleShopNames: PropTypes.array.isRequired,
  selectedShipmentCountry: PropTypes.string.isRequired,
  setSelectedShipmentCountry: PropTypes.func.isRequired,
  //sku select Options
  skuSelectedOption: PropTypes.string.isRequired,
  setSkuSelectedOption: PropTypes.func.isRequired,
  setSkuPage: PropTypes.func.isRequired,
  selectedShop: PropTypes.string.isRequired,
  setSelectedShop: PropTypes.func.isRequired,
  selectedShipmentCountrySku: PropTypes.string.isRequired,
  setSelectedShipmentCountrySku: PropTypes.func.isRequired,
  selectedPercent: PropTypes.string.isRequired,
  setSelectedPercent: PropTypes.func.isRequired,
  skuYearSelectStartDate: PropTypes.string.isRequired,
  wareHouse: PropTypes.array.isRequired,
  selectedSkuFilter: PropTypes.string.isRequired,
  setSelectedSkuFilter: PropTypes.func.isRequired
}

export default DrawerListAnalytices
