import React, { useContext, useState } from 'react'
import { CContainer } from '@coreui/react'
import { FormControl, Grid, MenuItem, Select, Skeleton } from '@mui/material'
import { usePurchaseOrderDetailsHooks } from './usePurchaseOrderDetailsHook'
import BasicTable from 'components/uiElements/table/BasicTable'
import ChatBox from 'components/chat/chatBox'
import ModalDialog from 'components/uiElements/modal/Dialog'
import ProposalForm from './ProposalForm'
import ProposalHistory from './ProposalHistory'
import ProposalValidation from './ProposalValidation'
import { AiOutlineAppstoreAdd, AiOutlineEdit } from 'react-icons/ai'
import { Button as ProductButton } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import PoHeader from 'pages/purchaseOrder/PoHeader'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { UserContext } from 'context/AuthContext/UserContext'
import Seo from 'components/common/seo'
import PoCancel from './PurchaseOrderCancel'
import { CiExport } from 'react-icons/ci'
import { exportJsonToCsvCustomRows, isUsertAuthorized } from 'shared/util/helper'
import Modal from 'components/uiElements/modal/Dialog'
import PurchaseorderTemplate from 'components/uiElements/purchaseorders/purchaseorder'
// import AddPoProduct from './AddPoProduct'

const PurchaseOrderDetails = (props) => {
  const { userData,userRole } = useContext(UserContext)
  const {
    showToast,
    updatedColumns,
    rows,
    page,
    rowsPerPage,
    isPoLoading,
    isLoading,
    messages,
    isOpen,
    modal,
    pId,
    isSubmitting,
    products,
    sku,
    newMessage,
    content,
    validating,
    handleChangePage,
    handleChangeRowsPerPage,
    sendMessage,
    setIsOpen,
    setModal,
    submitHandler,
    setNewMessage,
    validateSubmitHandler,
    data,
    purchaseOrderDetail,
    contextObj,
    updateMessage,
    fetchBaseUrl,
    cancelPo,
    isCancelPoLoading,
    EditPurchaseOrder
  } = usePurchaseOrderDetailsHooks(props)
  const [selectedPdfType, setSelectedPdfType] = useState('')
  const [showPdf, setShowPdf] = useState(false)

  const handleClose = () => {
    setModal(false)
  }
  const param = useParams()
  let breadCrumbPath = [
    { name: 'Purchase Order', url: `/purchaseorders`, active: 'not-allowed' },
    { name: `Purchase Order Details`, url: `/purchaseOrder/${param?.poNumber}`, active: 'not-allowed' }
  ]

  const downloadProjectAndOrders = async (project, orders) => {
    const rows = []
    rows.push(['Purchase Order Details'])
    rows.push([])
    const allowedKeys = [
      'poNumber',
      'supplierName',
      'shipTo',
      'shipVia',
      'status',
      'isDispatch',
      'poDate',
      'poLoadDate',
      'ready_date',
      'poDueDate'
    ]
    const keysRow = []
    const valuesRow = []
    for (const [key, value] of Object.entries(project)) {
      if (allowedKeys.includes(key)) {
        keysRow.push(key)
        valuesRow.push(value)
      }
    }
    rows.push(keysRow)
    rows.push(valuesRow)
    rows.push([])
    rows.push([])
    rows.push(['Products Details'])
    rows.push([])
    rows.push([
      'sku',
      'vendor',
      'upc',
      'width',
      'length',
      'FOB',
      'SQMeterPerPiece',
      'expectedQty',
      'proposedQty',
      'size',
      'totalFOB',
      'totalSqm',
      'validatedQty'
    ])
    await orders.forEach((order) => {
      rows.push([
        order.sku,
        order.vendor,
        order.upc,
        order.width,
        order.length,
        order.FOB,
        order.SQMeterPerPiece,
        order.expectedQty,
        order.proposedQty,
        order.size,
        order.totalFOB,
        order.totalSqm,
        order.validatedQty
      ])
    })
    await exportJsonToCsvCustomRows(rows, project.poNumber)
  }

  return (
    <>
      <Seo
        title='Purchase Order'
        description='Discover the World of InnoSales'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />
      <div className='p-5'>
        <Grid container>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
            <h3>Purchase Order Detail</h3>
            <p>Detail of your Purchase Order</p>
          </Grid>
          <Grid item lg={8} md={6} sm={6} xs={12}>
            <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
              {(userData?.user?.pomUserType === 1 || userData?.user?.pomUserType === 2) && isUsertAuthorized(userRole?.userSubscription?.roleCode, { role: 3, module: 3 }) ? (
                <Grid item>
                  <ProductButton
                    className='me-2'
                    variant='outlined'
                    component={Link}
                    to={`/purchaseOrder/edit/${param?.poNumber}`}
                  >
                    Edit &nbsp;&nbsp;
                    <AiOutlineEdit size='1.5rem' />
                  </ProductButton>
                </Grid>
              ) : (
                ''
              )}
              <Grid item>
                <FormControl
                  fullWidth
                  sx={{
                    backgroundColor: '#F9F9F9',
                    borderColor: 'primary.main',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main'
                    },
                    '& .MuiSelect-select': {
                      color: 'primary.main'
                    }
                  }}
                  size='small'
                  className='border-none'
                >
                  <Select
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span>
                            Export Details&nbsp;&nbsp; <CiExport size='1.5rem' />
                          </span>
                        )
                      }
                      return selected
                    }}
                    IconComponent={() => null}
                    value={selectedPdfType}
                    onChange={(e) => setSelectedPdfType(e.target.value)}
                  >
                    <MenuItem
                      value='Export Reports'
                      onClick={() => downloadProjectAndOrders(purchaseOrderDetail, rows)}
                    >
                      Export In CSV
                    </MenuItem>
                    <MenuItem
                      value='Export In PDF'
                      onClick={() => {
                        setShowPdf(true)
                        setSelectedPdfType('Export In PDF')
                      }}
                    >
                      Export In PDF
                    </MenuItem>
                    <MenuItem
                      value='Export In PDF'
                      onClick={() => {
                        showToast('Initiated Downloaded !', false)
                        fetchBaseUrl()
                      }}
                    >
                      Export Odoo Report
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <ProductButton
                  variant='outlined'
                  disabled={
                    purchaseOrderDetail.status == 'Quotation' ||
                    purchaseOrderDetail.status == 'Proposal' ||
                    purchaseOrderDetail.status == 'Cancelled' ||
                    purchaseOrderDetail.status == 'Request For Change'
                      ? true
                      : false
                  }
                  component={Link}
                  to={`/purchaseOrder/delivery/${param.poNumber}`}
                >
                  Delivery&nbsp;&nbsp;
                  <AiOutlineAppstoreAdd size='1.5rem' />
                </ProductButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal
          fullWidth
          maxWidth='md'
          open={showPdf}
          content={
            <PurchaseorderTemplate
              selectedPdfType={selectedPdfType}
              purchaseOrderDetail={purchaseOrderDetail}
              rows={rows}
            />
          }
          handleClose={() => {
            setShowPdf(false)
            setSelectedPdfType('')
          }}
        />

        <CContainer lg>
          <Grid>
            {isPoLoading ||
            Object.keys(purchaseOrderDetail)?.length === 0 &&
            purchaseOrderDetail?.constructor === Object ? (
              <Skeleton animation='wave' variant='rectangle' width='100%' height='350px' />
            ) : (
              <PoHeader purchaseOrderDetail={purchaseOrderDetail} editPurchaseOrder={EditPurchaseOrder} />
            )}
            <Grid>
              {rows.length > 0 ? (
                <BasicTable
                  columns={updatedColumns}
                  rows={rows}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  purchaseOrderDetail={purchaseOrderDetail}
                  isLoading={isLoading}
                  Nopagination={true}
                />
              ) : (
                ''
              )}
            </Grid>
            <ChatBox
              isOpen={isOpen}
              poId={purchaseOrderDetail._id}
              poNumber={purchaseOrderDetail.poNumber}
              userId={contextObj.userData.user.id}
              sendMessage={sendMessage}
              messages={messages}
              setIsOpen={setIsOpen}
              setNewMessage={setNewMessage}
              newMessage={newMessage}
              data={data}
              updateMessage={updateMessage}
              purchaseOrderDetail={purchaseOrderDetail}
              contextObj={contextObj}
            />
          </Grid>
        </CContainer>

        <ModalDialog
          maxWidth={content.history ? 'md' : ''}
          open={modal}
          content={
            <div>
              {content.history && (
                <ProposalHistory products={products} pId={pId} sku={sku} poDetails={purchaseOrderDetail} />
              )}
              {content.cancel && (
                <PoCancel
                  products={products}
                  pId={pId}
                  sku={sku}
                  poDetails={purchaseOrderDetail}
                  cancelAction={handleClose}
                  cancelPo={cancelPo}
                  isCancelPoLoading={isCancelPoLoading}
                />
              )}

              {content.proposal && (
                <ProposalForm
                  PoNumber={purchaseOrderDetail?._id}
                  ProductId={pId}
                  cancelAction={handleClose}
                  isSubmitting={isSubmitting}
                  submitHandler={submitHandler}
                  poDetails={purchaseOrderDetail}
                />
              )}
              {content.validation && (
                <ProposalValidation
                  PoId={purchaseOrderDetail._id}
                  pId={pId}
                  rows={rows}
                  ValidationSubmit={validateSubmitHandler}
                  validating={validating}
                  cancelAction={handleClose}
                  sku={sku}
                />
              )}
            </div>
          }
          handleClose={handleClose}
          title={
            (content.history && 'HISTORY') ||
            (content.proposal && 'SKU Proposal') ||
            (content.validation && 'Sku Validation')
          }
        />
        {/* <Modal
          open={false}
          // onClose={() => setOpenModel(false)}
          header={'Add Products'}
          fullWidth
          maxWidth='xl'
          content={
            <>
              <Grid>
                <AddPoProduct
                  vendorsAllProducts={vendorsAllProducts}
                  setVendorsAllProducts={setVendorsAllProducts}
                  values={newProduct}
                  handleChange={handleAddProduct}
                  supplier={purchaseOrderDetail}
                  setSkuValue={setSkuValue}
                  skuValue={skuValue}
                />
              </Grid>
            </>
          }
          handleClose={() => {
            // e.preventDefault()
            // props?.setProductDetails([])
            // props.setFilterProductId([])
            // setLoading(false)
            // props.getAllPurchaseRefetch()
            // setOpenModel(false)
          }}
        /> */}
      </div>
    </>
  )
}

export default PurchaseOrderDetails
