import React, { useState } from 'react'
import { useSalesReportHooks } from './useSalesReportHooks'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Button,
  TextField,
  Tooltip,
  Drawer,
  Collapse,
  Typography,
  Stack,
  Chip
} from '@mui/material'
import { GrPowerReset } from 'react-icons/gr'
import CustomisedInput from 'components/formElements/CustomisedInput'
import Seo from 'components/common/seo'
import DrawerList from './common/DrawerList'
import { BsFilterRight } from 'react-icons/bs'
import AdvertisementGraph from 'components/graph/advertisementGraph'
import SalesReportTable from './common/SalesReportTable'
import SalesReportShopTable from './common/SalesReportShopTable'
import SalesReportShopWiseTable from './common/SalesReportShopWiseTable'
import { BarChart } from '@mui/x-charts/BarChart'
import { currencyFormatter, exportJsonToCsvCustomRows, generateYearOptions } from 'shared/util/helper'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import PoReportTable from 'pages/dashboard/poReportTable'
import { ColoursHeadCells, SizePatternHeadCells, StylePatternHeadCells } from 'data/HeadCells'
import { BiExport } from 'react-icons/bi'

const SalesReport = () => {
  const {
    newParam,
    navigate,
    isLoading,
    warehouseData,
    shipmentData,
    shopData,
    saleTrendPage,
    setSaleTrendPage,
    stockAlertPage,
    setStockAlertPage,
    onChangeRouter,
    sortConfig,
    setSortConfig,
    onChangeFilter,
    toggleDrawer,
    isOpenDrawer,
    salesReportData,
    shopOverallData,
    shopOverallLoading,
    uniqueVendors,
    // REPORTTYPEDATA
    reportTypeData,
    reportTypeDataLoading,
    colourPatternData,
    colourPatternLoading,
    sizePatternData,
    sizePatternLoading,
    getSelectedMonths,
    handleMonthsChange,
    monthsOptions
  } = useSalesReportHooks()

  const getBaseShopName = (shop) => {
    if (shop.toLowerCase().includes('amazon')) {
      return 'Amazon'
    } else if (shop.toLowerCase().includes('mark')) {
      return 'Mark & Day'
    }
    return shop
  }
  const shopParam = newParam.get('shops_overall') || 'Wayfair'
  const baseShopName = getBaseShopName(shopParam)
  const overallHeading = `${baseShopName} (Overall)`
  const shopWiseHeading = `${baseShopName} - Shop Wise`

  const groupShopsByPrefix = (shops) => {
    const groupedShops = {}
    Array.isArray(shops) &&
      shops?.length &&
      shops?.forEach((shop) => {
        const shopName = typeof shop === 'string' ? shop : shop.shop
        const prefix = shopName.split(' ')[0]
        if (!groupedShops[prefix]) {
          groupedShops[prefix] = []
        }
        if (!groupedShops[prefix].includes(shopName)) {
          groupedShops[prefix].push(shopName)
        }
      })
    return groupedShops
  }

  const [expandedPrefix, setExpandedPrefix] = useState(null)
  const groupedShops = groupShopsByPrefix(shopData)

  const handlePrefixToggle = (prefix) => {
    setExpandedPrefix(expandedPrefix === prefix ? null : prefix)
  }
  const handleSelectChange = (value) => {
    onChangeRouter('shops_overall', value)
  }
  const selectedMonths = getSelectedMonths('overall_month')
  const years = generateYearOptions()
  const currentSelectedYear = newParam.get('selected_year') || new Date().getFullYear()
  const previousSelectedYear = currentSelectedYear - 1
  const reportType = newParam.get('report_type')
  const tableHeadCells =
    reportType === 'COLORS' ? ColoursHeadCells : reportType === 'SIZE' ? SizePatternHeadCells : StylePatternHeadCells

  const ExportClassificationReport = async (reportType, data) => {
    const rows = [
      [
        '',
        previousSelectedYear,
        '',
        '',
        '',
        currentSelectedYear,
        '',
        '',
        '',
        'Change',
        '',
        reportType === 'SIZE' ? 'Average Order Value' : ''
      ],
      [
        reportType === 'COLORS' ? 'Colours' : reportType === 'SIZE' ? 'Sizes' : 'Style/Pattern',
        'Quantity',
        'Untaxed',
        'Contribution (QTY)',
        'Contribution (PRICE)',
        'Quantity',
        'Untaxed',
        'Contribution (QTY)',
        'Contribution (PRICE)',
        'CHANGE (QTY)',
        'CHANGE (PRICE)',
        reportType === 'SIZE' ? 'AOV PREVIOUS' : '',
        reportType === 'SIZE' ? 'AOV CURRENT' : ''
      ]
    ]
    if (Array.isArray(data)) {
      data.map((res) => {
        rows.push([
          reportType === 'COLORS' ? res.color : reportType === 'SIZE' ? res.shipLength : res.pattern_Style,
          res.total_qty_previous_year,
          res.total_untaxed_previous_year,
          res.contribution_qty_prev,
          res.contribution_untaxed_prev,
          res.total_qty_current_year,
          res.total_untaxed_current_year,
          res.contribution_qty_current,
          res.contribution_untaxed_current,
          res.change_qty,
          res.change_untaxed,
          reportType === 'SIZE' ? res.average_order_previous : '',
          reportType === 'SIZE' ? res.average_order_current : ''
        ])
      })
    }
    await exportJsonToCsvCustomRows(rows, `${reportType} CLASSIFICATION`)
  }
  return (
    <>
      <Seo
        title='Sales Trends Alert'
        description='Discover the World of Surya Purchase Order Management'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />
      <section className='page-spacing'>
        <div className='container-fluid'>
          {/* ALL SHOPS FILTER */}
          <div className='common-card rounded-3 p-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 className='fw-bold advertisement-report'>
                  Sales Report <span></span>
                </h3>
                <div className='d-flex justify-content-center align-items-center'>
                  <h5 className='me-2 mt-2'>Select Report Year</h5>
                  <Grid className='me-3' item xs={12} sm={6} md={4} lg={1}>
                    <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                      <Select
                        value={newParam.get('selected_year') || new Date().getFullYear()}
                        displayEmpty
                        onChange={(e) => onChangeRouter('selected_year', e.target.value)}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <span>Select Report Year</span>
                          }
                          return selected
                        }}
                        sx={{
                          '& .MuiSelect-select': { padding: '10px 12px' },
                          color: '#333'
                        }}
                      >
                        <MenuItem value={new Date().getFullYear()}>Current Year</MenuItem>
                        {years.map((year) => (
                          <MenuItem className='text-success fw-bold' key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Tooltip title='Select filter for SalesReport' arrow>
                    <Button
                      className='rounded-3 fs-16'
                      variant='outlined'
                      onClick={toggleDrawer(true)}
                      style={{
                        backgroundColor: '#424242',
                        color: 'white',
                        textTransform: 'none',
                        fontSize: 16,
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: 130
                      }}
                    >
                      Filters&nbsp; &nbsp;
                      <BsFilterRight size='2rem' />
                    </Button>
                  </Tooltip>
                  <Drawer anchor='right' open={isOpenDrawer} onClose={toggleDrawer(false)}>
                    <DrawerList
                      toggleDrawer={toggleDrawer}
                      onChangeFilter={onChangeFilter}
                      warehouseData={warehouseData}
                      groupedShops={groupedShops}
                      shipmentData={shipmentData}
                      uniqueVendors={uniqueVendors}
                      getSelectedMonths={getSelectedMonths}
                      handleMonthsChange={handleMonthsChange}
                      monthsOptions={monthsOptions}
                    />
                  </Drawer>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* ALL SHOPS TABLE */}
          <div className='row mt-4'>
            <div className='col-md-12'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4>All Shops View</h4>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  <SalesReportTable
                    salesReportData={salesReportData}
                    saleTrendPerPage={50}
                    saleTrendPage={saleTrendPage}
                    setSaleTrendPage={setSaleTrendPage}
                    loading={isLoading}
                    isQuarterly={
                      newParam.get('month_quarter') === 'month_wise' || !newParam.get('month_quarter') ? false : true
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* SIZE COLOURS STYLE/PATTERN TABLES DATA */}
          <div className='common-card rounded-3 p-3 mt-4'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 className='fw-bold advertisement-report'>
                  Size,Colors,Style/Pattern Classifications <span></span>
                </h3>
                <div className='d-flex justify-content-center align-items-center'>
                  <h5 className='me-2 mt-2'>Classifications:</h5>
                  <Grid className='me-3' item xs={12} sm={6} md={4} lg={1}>
                    <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                      <Select
                        value={newParam.get('report_type') || 'Style/Pattern'}
                        displayEmpty
                        onChange={(e) => onChangeRouter('report_type', e.target.value)}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <span>Classifications</span>
                          }
                          return selected
                        }}
                        sx={{
                          '& .MuiSelect-select': { padding: '10px 12px' },
                          color: '#333'
                        }}
                      >
                        <MenuItem value={'STYLE'}>STYLE/PATTERN</MenuItem>
                        <MenuItem value={'COLORS'}>COLORS</MenuItem>
                        <MenuItem value={'SIZE'}>SIZE</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('report_filter') || ''}
                      displayEmpty
                      onChange={(e) => onChangeRouter('report_filter', e.target.value)}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Complete Year</span>
                        }
                        return selected
                      }}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                    >
                      <MenuItem value=''>Complete Year</MenuItem>
                      <MenuItem value='WINTER'>WINTER</MenuItem>
                      <MenuItem value='SPRING'>SPRING</MenuItem>
                      <MenuItem value='SUMMER'>SUMMER</MenuItem>
                      <MenuItem value='ANTHUM'>ANTHUM</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() =>
                      ExportClassificationReport(
                        newParam.get('report_type') || 'STYLEPATTERN',
                        reportType === 'COLORS'
                          ? colourPatternData?.data
                          : reportType === 'SIZE'
                          ? sizePatternData?.data
                          : reportTypeData?.data
                      )
                    }
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <BiExport className='me-2' size='1.0rem' />
                    Export
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      navigate('/salesreport')
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className='row mt-4'>
            <div className='col-md-12 h-100'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4>{newParam.get('report_type') || 'Style/Pattern'} Classifications</h4>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  {newParam.get('report_type') === 'COLORS' ? (
                    <PoReportTable
                      key={reportType}
                      currentSelectedYear={currentSelectedYear}
                      previousSelectedYear={previousSelectedYear}
                      tableHeadCells={tableHeadCells}
                      count={10}
                      ReportList={colourPatternData?.data}
                      ReportLoading={colourPatternLoading}
                      pagination={false}
                    />
                  ) : newParam.get('report_type') === 'SIZE' ? (
                    <PoReportTable
                      key={reportType}
                      currentSelectedYear={currentSelectedYear}
                      previousSelectedYear={previousSelectedYear}
                      tableHeadCells={tableHeadCells}
                      count={10}
                      ReportList={sizePatternData?.data}
                      ReportLoading={sizePatternLoading}
                      pagination={false}
                    />
                  ) : (
                    <PoReportTable
                      key={reportType}
                      currentSelectedYear={currentSelectedYear}
                      previousSelectedYear={previousSelectedYear}
                      tableHeadCells={tableHeadCells}
                      count={10}
                      ReportList={reportTypeData?.data}
                      ReportLoading={reportTypeDataLoading}
                      pagination={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Particular Shops Filters Filters */}
          <div className='common-card rounded-3 mt-4'>
            <h4 className='ms-4 m-0 fw-bold advertisement-report'>
              Each Shop Report <span></span>
            </h4>
            <Grid className='p-2 ms-2 ' item xs={12} sm={6} md={4} lg={2}>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      multiple
                      displayEmpty
                      IconComponent={() => null}
                      value={selectedMonths}
                      onChange={(e) => handleMonthsChange(e, 'overall_Month')}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Select Multiple Months</span>
                        }
                        const displayCount = 1
                        const hiddenCount = selected.length - displayCount
                        return (
                          <Stack gap={1} direction='row' flexWrap='wrap'>
                            {selected.slice(0, displayCount).map((value) => (
                              <Chip
                                key={value}
                                label={monthsOptions.find((month) => month.value === value)?.label}
                                onDelete={() =>
                                  handleMonthsChange({ target: { value: selected.filter((item) => item !== value) } })
                                }
                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                              />
                            ))}
                            {hiddenCount > 0 && <Chip label={`+${hiddenCount} more`} />}
                          </Stack>
                        )
                      }}
                    >
                      <MenuItem value='all'>All Months</MenuItem>
                      {monthsOptions.map((month) => (
                        <MenuItem key={month.value} value={month.value} sx={{ justifyContent: 'space-between' }}>
                          {month.label}
                          {selectedMonths.includes(month.value) ? <CheckIcon color='info' /> : null}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Tooltip title={'Search Sku'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='Sku or Design'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={newParam.get('sku_overall') || ''}
                        input={<CustomisedInput />}
                        onChange={(e) => onChangeRouter('sku_overall', e?.target?.value.trim())}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('shops_overall') || ''}
                      displayEmpty
                      onChange={(e) => handleSelectChange(e.target.value)}
                      renderValue={(selected) => {
                        if (!selected) return 'Wayfair (Overall)'
                        const foundShop = Object.values(groupedShops)
                          .flat()
                          .find((shop) => shop === selected)
                        return foundShop || selected
                      }}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 400
                          }
                        }
                      }}
                    >
                      <MenuItem value=''>Shops</MenuItem>
                      {Object.keys(groupedShops).map((prefix) => {
                        const shops = groupedShops[prefix]

                        if (shops.length === 1) {
                          return (
                            <MenuItem
                              key={shops[0]}
                              value={shops[0]}
                              selected={newParam.get('shops_overall') === shops[0]}
                              onClick={() => handleSelectChange(shops[0])}
                            >
                              {shops[0]}
                            </MenuItem>
                          )
                        } else {
                          return (
                            <React.Fragment key={prefix}>
                              <MenuItem
                                onClick={() => handlePrefixToggle(prefix)}
                                sx={{ fontWeight: 'bold', justifyContent: 'space-between' }}
                              >
                                <Typography sx={{ fontWeight: 'bold' }}>
                                  {prefix === 'Mark' ? 'Mark & Day' : prefix}
                                </Typography>
                                <ExpandMoreIcon
                                  sx={{
                                    transition: 'transform 0.2s',
                                    transform: expandedPrefix === prefix ? 'rotate(180deg)' : 'rotate(0deg)'
                                  }}
                                />
                              </MenuItem>
                              <Collapse in={expandedPrefix === prefix} timeout='auto' unmountOnExit>
                                <MenuItem
                                  key={prefix}
                                  value={prefix}
                                  sx={{ pl: 4 }}
                                  selected={newParam.get('shops_overall') === prefix}
                                  onClick={() => handleSelectChange(prefix)}
                                >
                                  {prefix === 'Mark' ? 'Mark & Day' : prefix}
                                </MenuItem>
                                {shops.map((shop) => (
                                  <MenuItem
                                    key={shop}
                                    value={shop}
                                    sx={{ pl: 4 }}
                                    selected={newParam.get('shops_overall') === shop}
                                    onClick={() => handleSelectChange(shop)}
                                  >
                                    {shop}
                                  </MenuItem>
                                ))}
                              </Collapse>
                            </React.Fragment>
                          )
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('shipment_country_overall') || ''}
                      displayEmpty
                      onChange={(e) => onChangeRouter('shipment_country_overall', e.target.value)}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Shipment Country</span>
                        }
                        return selected
                      }}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                    >
                      <MenuItem value=''>Shipment Country</MenuItem>
                      {shipmentData?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('warehouse_overall') || ''}
                      displayEmpty
                      onChange={(e) => onChangeRouter('warehouse_overall', e.target.value)}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                    >
                      <MenuItem value=''>Warehouse</MenuItem>
                      {warehouseData?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('status_overall') || ''}
                      displayEmpty
                      onChange={(e) => onChangeRouter('status_overall', e.target.value)}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                    >
                      <MenuItem value=''>Status</MenuItem>
                      <MenuItem value='closed'>Closed</MenuItem>
                      <MenuItem value='shipped'>Shipped</MenuItem>
                      <MenuItem value='returned'>Returned</MenuItem>
                      <MenuItem value='cancelled'>Cancelled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('shop_type_overall') || ''}
                      displayEmpty
                      onChange={(e) => onChangeRouter('shop_type_overall', e.target.value)}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                    >
                      <MenuItem value=''>Shop Type</MenuItem>
                      <MenuItem value='B2B'>B2B</MenuItem>
                      <MenuItem value='B2C'>B2C</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('vendor_overall') || ''}
                      displayEmpty
                      onChange={(e) => onChangeRouter('vendor_overall', e.target.value)}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Vendor</span>
                        }
                        return selected
                      }}
                      sx={{
                        '& .MuiSelect-select': { padding: '10px 12px' },
                        color: '#333'
                      }}
                    >
                      <MenuItem value=''>Vendor</MenuItem>
                      {uniqueVendors?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                    <Select
                      value={newParam.get('commerce_type_overall')}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Commerce Type'
                        }
                        const menuItems = [
                          { value: 'ecomm', label: 'Ecomm' },
                          { value: 'false', label: 'Non Ecomm' }
                        ]
                        const selectedItem = menuItems.find((item) => item.value === selected)
                        return selectedItem ? selectedItem.label : 'Commerce Type'
                      }}
                      onChange={(e) => onChangeRouter('commerce_type_overall', e.target.value)}
                      IconComponent={() => null}
                    >
                      <MenuItem value=''>Commerce Type</MenuItem>
                      <MenuItem value='ecomm'>Ecomm</MenuItem>
                      <MenuItem value='false'>Non Ecomm</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      navigate('/salesreport')
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* Particular Shops Listing */}
          <div className='row mt-4'>
            <div className='col-md-12 h-100'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4>{overallHeading}</h4>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  <SalesReportShopTable
                    currentSelectedYear={currentSelectedYear}
                    previousSelectedYear={previousSelectedYear}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    shopOverallData={shopOverallData}
                    setStockAlertPage={setStockAlertPage}
                    stockAlertPage={stockAlertPage}
                    loading={shopOverallLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-12 h-100'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4>{shopWiseHeading}</h4>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  <SalesReportShopWiseTable
                    sortConfig={sortConfig}
                    currentSelectedYear={currentSelectedYear}
                    previousSelectedYear={previousSelectedYear}
                    setSortConfig={setSortConfig}
                    stockAlertData={shopOverallData}
                    setStockAlertPage={setStockAlertPage}
                    stockAlertPage={stockAlertPage}
                    loading={shopOverallLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* GRAPH LINE */}
          {shopOverallData?.qtyComparison?.xLabels?.length && shopOverallData?.untaxedComparison?.xLabels.length ? (
            <section className='dashboard-graph mt-4 mb-2'>
              <div>
                <div className='row gy-3'>
                  <div className='col-lg-6 px-xs-0'>
                    <div className='common-card rounded-2 h-100'>
                      <div className='common-card-content border-0 advertisement-graph-container'>
                        <AdvertisementGraph
                          xLabels={shopOverallData?.qtyComparison?.xLabels}
                          label={[`QTY Ordered ${previousSelectedYear}`, `QTY Ordered ${currentSelectedYear}`]}
                          graphData={[
                            shopOverallData?.qtyComparison?.qty_2023,
                            shopOverallData?.qtyComparison?.qty_2024
                          ]}
                          leftAxisFormatter={(value) => value}
                          rightAxisFormatter={(value) => value}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 px-xs-0'>
                    <div className='common-card rounded-3 h-100'>
                      <div className='common-card-content border-0 advertisement-graph-container'>
                        <AdvertisementGraph
                          xLabels={shopOverallData?.untaxedComparison?.xLabels}
                          label={[`Untaxed Total ${previousSelectedYear}`, `Untaxed Total ${currentSelectedYear}`]}
                          graphData={[
                            shopOverallData?.untaxedComparison?.untaxed_2023,
                            shopOverallData?.untaxedComparison?.untaxed_2024
                          ]}
                          leftAxisFormatter={currencyFormatter}
                          rightAxisFormatter={(value) => value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ''
          )}
          {/* BAR GRAPH */}
          {shopOverallData?.shopComparison?.xLabels?.length ||
          shopOverallData?.shopUntaxedComparison?.xLabels.length ? (
            <section className='dashboard-graph mt-4 mb-2'>
              <div>
                <div className='row gy-3'>
                  <div className='col-lg-6 px-xs-0'>
                    <div className='common-card rounded-2 h-100'>
                      <div className='common-card-content border-0 advertisement-graph-container'>
                        <BarChart
                          series={[
                            {
                              data: shopOverallData?.shopQtyComparison?.qty_2023,
                              label: `QTY Ordered ${previousSelectedYear}`,
                              color: '#2e96ff'
                            },
                            {
                              data: shopOverallData?.shopQtyComparison?.qty_2024,
                              label: `QTY Ordered ${currentSelectedYear}`,
                              color: '#02b2af'
                            }
                          ]}
                          xAxis={[
                            {
                              data: shopOverallData?.shopQtyComparison?.xLabels,
                              scaleType: 'band'
                            }
                          ]}
                        ></BarChart>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 px-xs-0'>
                    <div className='common-card rounded-3 h-100'>
                      <div className='common-card-content border-0 advertisement-graph-container'>
                        <BarChart
                          series={[
                            {
                              data: shopOverallData?.shopUntaxedComparison?.untaxed_2023,
                              label: `Untaxed Total ${previousSelectedYear}`,
                              color: '#2e96ff'
                            },
                            {
                              data: shopOverallData?.shopUntaxedComparison?.untaxed_2024,
                              label: `Untaxed Total ${currentSelectedYear}`,
                              color: '#02b2af'
                            }
                          ]}
                          xAxis={[
                            {
                              data: shopOverallData?.shopUntaxedComparison?.xLabels,
                              scaleType: 'band'
                            }
                          ]}
                        ></BarChart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  )
}

export default SalesReport
