/* eslint-disable no-unused-vars */
import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CBadge, CTooltip } from '@coreui/react'
import { UserContext } from 'context/AuthContext/UserContext'

export const AppSidebarNav = ({ items }) => {
  const contextObj = React.useContext(UserContext)
  let roleCode = contextObj?.userRole?.userSubscription?.roleCode || contextObj?.userData?.user?.role
  const navLink = (name, icon, badge, userType) => {
    const tooltipContent = name
    return (
      <CTooltip content={tooltipContent}>
        <div className='d-flex align-items-center'>
          {icon && icon}
          {name && name}
          {badge && (
            <CBadge color={badge.color} className='ms-auto'>
              {badge.text}
            </CBadge>
          )}
        </div>
      </CTooltip>
    )
  }

  const navItem = (item, index) => {
    const { userType, component, name, badge, icon, ...rest } = item
    const Component = component
    if (
      ((userType === 1 || userType === 4) && contextObj?.userData?.user?.pomUserType === 1) ||
      ((userType === 2 || userType === 4) && contextObj.userData.user.pomUserType === 2) ||
      ((userType === 3 || userType === 4) && contextObj.userData.user.pomUserType === 3) ||
      ((userType === 4 || userType === 5) && contextObj.userData.user.pomUserType === 4)
    ) {
      return (
        <Component
          {...(rest.to &&
            !rest.items && {
              component: NavLink,
              activeclassname: 'active'
            })}
          key={index}
          {...rest}
        >
          {navLink(name, icon, badge, userType)}
        </Component>
      )
    }
  }
  console.log(roleCode," rolecode ")
  return (
    <>
      {items.length &&
        items.map((item, index) =>
          roleCode.some(
            (accessRole) => accessRole.role >= item?.roleCode?.role && accessRole.module == item.roleCode?.module
          )
            ? navItem(item, index)
            : ''
        )}
    </>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired
}
