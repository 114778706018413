import apiClient from 'api/apiClient'
import { endpoints } from 'api/endpoints'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
const logout = () => {
  // ARUN - API CALL to expire token
  localStorage.removeItem('iauser')
}

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem('iauser'))
  if (isNotNullUndefinedOrEmpty(user)) {
    const currentDate = new Date().toISOString()
    if (Date.parse(user.tokenExpiration) > Date.parse(currentDate)) {
      return user
    } else {
      logout()
      return ''
    }
  }
}
const getCurrentUserRole = async () => {
  const res = JSON.parse(localStorage.getItem('iauser'))
  if (!isNotNullUndefinedOrEmpty(res)) return
  const user = await apiClient.get(endpoints.USER_PROFILE)
  if (isNotNullUndefinedOrEmpty(user)) {
    return user
  }
}

const isLoggedIn = () => {
  return isNotNullUndefinedOrEmpty(getCurrentUser())
}

const userService = {
  logout,
  getCurrentUser,
  isLoggedIn,
  getCurrentUserRole
}

export default userService
