/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import {
  IconButton,
  Tooltip,
  Grid,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer
} from '@mui/material'
import PropTypes from 'prop-types'
import './User.css'
import { FiEdit } from 'react-icons/fi'
import image from '../../../assets/images/profile_placeholder.png'
import { UserContext } from 'context/AuthContext/UserContext'
import { isUsertAuthorized } from 'shared/util/helper'
import { UserModules } from 'data/Enums'
import { AiOutlineEye } from 'react-icons/ai'

const User = (props) => {
  const {
    users = [],
    userType,
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDeactivatedUsersClick,
    handleActivatedUsersClick,
    setisAddUser,
    setParticularUserId
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRole, setSelectedRole] = useState('')
  const { newParam, onChangeRouter, userData } = useContext(UserContext)
  const selectedStatus = newParam.get('USER') || 'All'
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setPage(0)
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value)
    setPage(0)
  }

  const handleStatusChange = (event) => {
    if (event.target.value === 'Deactivated') {
      handleDeactivatedUsersClick()
    } else {
      handleActivatedUsersClick()
    }
    setPage(0)
  }

  const handleRoleClick = (roleCode) => {
    setSelectedRole(roleCode)
    setPage(0)
  }

  function getUserCount(accessModule) {
    console.log(users, 'user count ')
    const count = users.filter((user) =>
      user?.userSubscription?.roleCode?.some((accessRole) => accessRole.module >= accessModule)
    ).length
    return `${count} Total Users`
  }

  const data = [
    { title: 'Account', amount: getUserCount(7), color: '#367588', roleCode: 3 },
    { title: 'Product', amount: getUserCount(2), color: '#367588', roleCode: 3 },
    { title: 'Project', amount: getUserCount(4), color: '#367588', roleCode: 5 },
    { title: 'Report', amount: getUserCount(5), color: '#367588', roleCode: 6 },
    { title: 'Purchaseorder', amount: getUserCount(3), color: '#367588', roleCode: 4 },
    { title: 'Refund', amount: getUserCount(6), color: '#214946', roleCode: 2 },
    { title: 'Dashboard', amount: getUserCount(1), color: '#214946', roleCode: 2 },
    { title: 'RetailShop', amount: getUserCount(9), color: '#214946', roleCode: 6 },
    { title: 'CustomerSupport', amount: getUserCount(8), color: '#214946', roleCode: 4 }
  ]

  const filteredUsers = users.filter((user) => {
    const fullName = `${user.fName} ${user.lName}`.toLowerCase()
    const matchesSearch =
      fullName.includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesRole = selectedRole
      ? isUsertAuthorized(user?.userSubscription?.roleCode, { module: selectedRole }, 'module')
      : true
    const matchesStatus =
      selectedStatus === 'All'
        ? true
        : selectedStatus === 'Activated'
        ? user?.isActive
        : selectedStatus === 'Deactivated'
        ? !user?.isActive
        : true
    return matchesSearch && matchesRole && matchesStatus
  })
  const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const checkUserType = userData?.user?.pomUserType === 1 ? data : data.slice(0, 4)
  return (
    <>
      {userType ? (
        <h2 className='mt-2' style={{ fontSize: '1.5rem', textAlign: 'left', padding: '10px 0' }}>
          Roles List With Details
        </h2>
      ) : (
        ''
      )}

      <Grid container spacing={2} className='mt-3 mb-4'>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label='Search User'
            variant='outlined'
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>
        {userType ? (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Module</InputLabel>
              <Select value={selectedRole} onChange={handleRoleChange} label='Search Role'>
                <MenuItem value=''>
                  <em>User Access Module</em>
                </MenuItem>
                {UserModules.map((userAccessModule, index) => (
                  <MenuItem key={index} value={userAccessModule.value}>
                    {userAccessModule.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Search Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => {
                handleStatusChange(e), onChangeRouter('USER', e.target.value)
              }}
              label='Search Status'
            >
              <MenuItem value=''>
                <em>Search Users</em>
              </MenuItem>
              <MenuItem value='Activated'>Activated</MenuItem>
              <MenuItem value='Deactivated'>Deactivated</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              {!userType ? (
                <TableCell>
                  <strong>Company Name</strong>
                </TableCell>
              ) : (
                ''
              )}
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                <strong>Last Logged In</strong>
              </TableCell>
              {userType ? (
                <TableCell>
                  <strong>User Access</strong>
                </TableCell>
              ) : (
                ''
              )}
              <TableCell>
                <strong>{userType ? 'Action' : ''}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(paginatedUsers) && paginatedUsers.length ? (
              paginatedUsers.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Tooltip
                      title={<img src={item?.image ? item?.image : image} alt='Product' width='200px' />}
                      arrow
                      placement='right'
                      PopperProps={{
                        style: { marginLeft: '10px' }
                      }}
                    >
                      {item?.fName} {item?.lName}
                    </Tooltip>
                  </TableCell>
                  <TableCell>{item?.email}</TableCell>
                  {!userType ? <TableCell>{item?.companyName ? item?.companyName : 'N/A'}</TableCell> : ''}

                  <TableCell className={`fw-bold ${item?.isActive ? 'text-success' : 'text-danger'}`}>
                    {item?.isActive ? 'Activated' : 'Deactivated'}
                  </TableCell>
                  <TableCell>
                    {item?.refreshTokenCreated && item.refreshTokenCreated !== '0001-01-01T00:00:00'
                      ? new Date(item.refreshTokenCreated)
                          .toLocaleString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false
                          })
                          .replace(',', '')
                      : 'N/A'}
                  </TableCell>
                  {userType ? (
                    <TableCell>
                      <IconButton
                        aria-label='view'
                        color='success'
                        onClick={() => {
                          setParticularUserId(item.id)
                          setisAddUser(true)
                        }}
                      >
                        <AiOutlineEye />
                      </IconButton>
                    </TableCell>
                  ) : (
                    ''
                  )}
                  {userType ? (
                    <TableCell>
                      {setisAddUser && (
                        <Tooltip title={'Edit User'}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton
                              className='hover-effect'
                              aria-label='Edit User'
                              component='span'
                              onClick={() => {
                                setParticularUserId(item.id)
                                setisAddUser(true)
                              }}
                            >
                              <FiEdit size='0.8em' />
                            </IconButton>
                          </div>
                        </Tooltip>
                      )}
                    </TableCell>
                  ) : (
                    ''
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={6}>
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className='mt-4'
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

User.propTypes = {
  userType: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  setisAddUser: PropTypes.func.isRequired,
  setParticularUserId: PropTypes.func.isRequired,
  handleDeactivatedUsersClick: PropTypes.func,
  handleActivatedUsersClick: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired
}

User.defaultProps = {
  handleDeactivatedUsersClick: () => {},
  handleActivatedUsersClick: () => {}
}

export default User
