import React, { useContext, useRef, useState } from 'react'
import {
  Grid,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Skeleton,
  TablePagination
} from '@mui/material'
import PropTypes from 'prop-types'
import { UserContext } from 'context/AuthContext/UserContext'
import { FaEye } from 'react-icons/fa'
import ModalDialog from 'components/uiElements/modal/Dialog'
import moment from 'moment'

const PoReportTable = (props) => {
  const { ReportLoading, ReportList, count, tableHeadCells, pagination, currentSelectedYear, previousSelectedYear } =
    props
  const { onChangeRouter, newParam, navigate, location } = useContext(UserContext)
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const handleModalOpen = (item) => {
    setModalData(item || {})
    setOpenModal(true)
  }
  const tableContainerRef = useRef(null)
  return (
    <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'} className='bg-color'>
      <TableContainer className='scroll-box' ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead className='pb-3 card-chart'>
            {!location.pathname.startsWith('/salesreport') ? (
              ''
            ) : (
              <TableRow>
                <TableCell className='textalign' />
                <TableCell colSpan={4} className='textalign fw-bold'>
                  {previousSelectedYear}
                </TableCell>
                <TableCell colSpan={4} className='textalign fw-bold'>
                  {currentSelectedYear}
                </TableCell>
                <TableCell colSpan={2} className='textalign fw-bold'>
                  Change
                </TableCell>
                {newParam.get('report_type') === 'SIZE' && (
                  <TableCell colSpan={2} className='textalign fw-bold'>
                    Average Order Value
                  </TableCell>
                )}
              </TableRow>
            )}
            <TableRow className={pagination ? `row-color` : ''}>
              {tableHeadCells.map((cell) => {
                return (
                  <TableCell key={cell.label} className='textalign'>
                    <strong style={{ color: `${pagination ? '#ffffff' : ''}` }}>{cell.label}</strong>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {ReportLoading ? (
            <>
              <TableRow>
                <TableCell colSpan={500}>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div key={'div' + index} style={{ marginBottom: '0' }}>
                      <Skeleton key={'value' + index} animation='wave' height={100} />
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableBody>
              {Array.isArray(ReportList) && ReportList.length ? (
                ReportList.map((item, index) => {
                  return (
                    <TableRow key={item?.sku + ' ' + index}>
                      {tableHeadCells.map((cell) => {
                        if (cell.id === 'PoNumber') {
                          return (
                            <TableCell key={cell.id} className='textalign'>
                              <span>
                                <button
                                  onClick={() => navigate(`/purchaseOrder/${item[cell.id]}`)}
                                  className='tracking_code'
                                >
                                  <span className='cat-title'>{item[cell.id] || '--'}</span>
                                </button>
                              </span>
                            </TableCell>
                          )
                        }
                        if (cell.id === 'SupplierName') {
                          return (
                            <TableCell className='textalign' key={cell.id}>
                              {item[cell.id].split(' ')[0] || '--'}
                            </TableCell>
                          )
                        }
                        // Start Style Pattern Colours and Size keys modified
                        if (cell.id === 'pattern_Style' || cell.id === 'color' || cell.id === 'shipLength') {
                          return (
                            <TableCell className='textalign fw-bold' key={cell.id}>
                              {item[cell.id] || '--'}
                            </TableCell>
                          )
                        }
                        if (cell.id === 'total_untaxed_previous_year') {
                          return (
                            <TableCell className='textalign' key={cell.id}>
                              {item[cell.id] ? `€ ${item[cell.id].toFixed(2)}` : 0}
                            </TableCell>
                          )
                        }
                        if (cell.id === 'total_untaxed_current_year') {
                          return (
                            <TableCell className='textalign' key={cell.id}>
                              {item[cell.id] ? `€ ${item[cell.id].toFixed(2)}` : 0}
                            </TableCell>
                          )
                        }
                        if (cell.id === 'change_qty' || cell.id === 'change_untaxed') {
                          return (
                            <TableCell
                              className={`textalign fw-bold ${item[cell.id] > 0 ? 'text-success' : 'text-danger'} `}
                              key={cell.id}
                            >
                              {item[cell.id].toFixed(2) || 0}
                            </TableCell>
                          )
                        }
                        // Start Style Pattern Colours and Size keys modified
                        if (cell.id === 'warehouses') {
                          return (
                            <TableCell className='textalign' key={cell.id}>
                              <FaEye
                                style={{ cursor: 'pointer', color: 'green' }}
                                onClick={() => handleModalOpen(item)}
                                size={'1.5rem'}
                              />
                            </TableCell>
                          )
                        }
                        if (cell.id === 'last_warehouse_date') {
                          return (
                            <TableCell className='textalign' key={cell.id}>
                              {item[cell.id] ? moment(item[cell.id]).format('DD/MM/YYYY') : '--'}
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={cell.id} className='textalign'>
                            {item[cell.id] != null ? item[cell.id] : '--'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 30,
                        marginBottom: 30
                      }}
                    >
                      <p className='text-center'>No Records Found... </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component='div'
          style={{ marginTop: '10px' }}
          count={count}
          rowsPerPage={newParam.get('limit') || 10}
          page={parseInt(newParam.get('page'), 10) || 0}
          onPageChange={(e, newPage) => {
            onChangeRouter('page', newPage)
          }}
          onRowsPerPageChange={(e) => {
            onChangeRouter('limit', e.target.value)
          }}
          data-testid='table-pagination'
        />
      ) : (
        ''
      )}
      <ModalDialog
        open={openModal}
        fullWidth
        isDivider='true'
        title={
          <div>
            <h5 className='fw-semibold'>
              Sku Ages <span className='text-success ms-2'> {modalData.sku ? `(${modalData.sku})` : ''}</span>
            </h5>
          </div>
        }
        content={
          <Grid container direction='column' spacing={2}>
            <Grid item xs={12}>
              <div className='modal-table-container'>
                <table className='table table-striped m-0'>
                  <thead>
                    <tr>
                      <th>Warehouse</th>
                      <th>Entry Date</th>
                      <th>Last Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData?.warehouses?.map((warehouse, index) => (
                      <tr key={index}>
                        <td>{warehouse?.warehouse || '--'}</td>
                        <td>{warehouse?.setDate ? moment(warehouse?.setDate).format('DD/MM/YYYY') : '--'}</td>
                        <td>
                          {warehouse?.last_added_date ? moment(warehouse?.last_added_date).format('DD/MM/YYYY') : '--'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        }
        handleClose={() => setOpenModal(false)}
      />
    </Grid>
  )
}
PoReportTable.propTypes = {
  ReportLoading: PropTypes.bool,
  tableHeadCells: PropTypes.arrayOf(PropTypes.element).isRequired,
  ReportList: PropTypes.array,
  count: PropTypes.number,
  pagination: PropTypes.bool,
  currentSelectedYear: PropTypes.number,
  previousSelectedYear: PropTypes.number
}

export default PoReportTable
