import React, { useRef } from 'react'
import { useTrendsHooks } from './useTrendsHooks'
import { Grid, Select, MenuItem, FormControl, Button, TextField, Tooltip } from '@mui/material'
import { GrPowerReset } from 'react-icons/gr'
import TrendsSalesTable from './common/trendsSalesTable'
import TrendsStockTable from './common/trendsStockTable'
import { BsGraphDownArrow, BsGraphUpArrow } from 'react-icons/bs'
import { calculatePercentageDifference, camelCaseToTitleCase, getCurrentMonthMinusOne } from 'shared/util/helper'
import CustomisedInput from 'components/formElements/CustomisedInput'
import Seo from 'components/common/seo'
import { BiExport, BiImport } from 'react-icons/bi'
import ModalDialog from 'components/uiElements/modal/Dialog'
import moment from 'moment'
import PoReportTable from 'pages/dashboard/poReportTable'
import { NewTrendReportHeadCells } from 'data/HeadCells'

const Trends = () => {
  const {
    newParam,
    navigate,
    showToast,
    initilizeStockAlertList,
    loading,
    warehouseData,
    shipmentData,
    shopData,
    supplierData,
    saleTrendPage,
    setSaleTrendPage,
    trendsData,
    alertLoading,
    stockAlertPage,
    setStockAlertPage,
    stockAlertData,
    onChangeRouter,
    setStockAlertData,
    sortConfig,
    setSortConfig,
    onChangeFilter,
    openModal,
    setOpenModal,
    fetchWareHouseHangfire,
    newStockReport,
    newStockReportLoading,
    ExportNewReport
  } = useTrendsHooks()
  const TrendGrowth =
    Math.sign(calculatePercentageDifference(trendsData?.untaxed_total_current, trendsData?.untaxed_total_previous)) ===
    1
      ? true
      : false
  const tableRef = useRef(null)
  const handleExportClick = () => {
    if (tableRef.current) {
      tableRef.current.exportToXlsx()
    }
  }

  return (
    <>
      <Seo
        title='Sales Trends Alert'
        description='Discover the World of Surya Purchase Order Management'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />
      <section className='page-spacing'>
        <div className='container-fluid'>
          {/* Sales Trends Alert Filters */}
          <div className='common-card rounded-3'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid>
                <h3 className='fw-bold advertisement-report'>
                  Sales Trends Alert <span></span>
                </h3>
                {loading ? (
                  ''
                ) : (
                  <p className='fw-semibold'>
                    {trendsData?.untaxed_total_current
                      ? `€ ${trendsData?.untaxed_total_current?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : ''}
                    <button
                      className='ms-2 rounded-3 growth'
                      style={{ backgroundColor: TrendGrowth ? '#34C085' : '#FF0000' }}
                    >
                      Growth
                    </button>
                    {TrendGrowth ? (
                      <BsGraphUpArrow className='ms-2' fontSize={'1.5rem'} color='#34C085' />
                    ) : (
                      <BsGraphDownArrow className='ms-2' fontSize={'1.5rem'} color='#FF0000' />
                    )}{' '}
                    <span className='ms-3'>{`${calculatePercentageDifference(
                      trendsData?.untaxed_total_current,
                      trendsData?.untaxed_total_previous
                    ).toFixed(2)}%  ${camelCaseToTitleCase(
                      newParam.get('period_date') || 'last8Weeks'
                    )} Growth Rate`}</span>
                  </p>
                )}
              </Grid>
              {/* FILTER INPUTS */}
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1.0}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return `Select type`
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      value={camelCaseToTitleCase(newParam.get('period_date')) || 'last8Weeks'}
                      onChange={(e) => onChangeRouter('period_date', e.target.value)}
                    >
                      <MenuItem value='last8Weeks'>Last 8 Weeks</MenuItem>
                      <MenuItem value='lastMonth'>{getCurrentMonthMinusOne()}</MenuItem>
                      <MenuItem value='quaterly'>Quaterly</MenuItem>
                      <MenuItem value='monthly'>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Tooltip title={'Search Sku'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='Search Sku'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={newParam.get('sku') || ''}
                        input={<CustomisedInput />}
                        onChange={(e) => {
                          onChangeRouter('sku', e?.target?.value.trim())
                        }}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: {
                          max: new Date().toISOString().split('T')[0]
                        }
                      }}
                      IconComponent={() => null}
                      value={
                        newParam?.get('start_date')
                          ? new Date(newParam.get('start_date')).toISOString().split('T')[0]
                          : ''
                      }
                      onChange={(e) => onChangeFilter('start_date', e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <TextField
                      type='date'
                      size='small'
                      InputLabelProps={{
                        shrink: true
                      }}
                      IconComponent={() => null}
                      value={
                        newParam.get('end_date') ? new Date(newParam.get('end_date')).toISOString().split('T')[0] : ''
                      }
                      disabled={!newParam.get('start_date')}
                      onChange={(e) => onChangeFilter('end_date', e.target.value)}
                      InputProps={{
                        inputProps: {
                          min: newParam.get('start_date')
                            ? new Date(newParam.get('start_date')).toISOString().split('T')[0]
                            : null,
                          max: new Date().toISOString().split('T')[0]
                        }
                      }}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam?.get('warehouse') || ''}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Warehouse</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeRouter('warehouse', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Warehouse</MenuItem>
                      {warehouseData?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('shops')}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>All Shop</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeRouter('shops', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Shop</MenuItem>
                      {shopData.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('shipment_country')}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Shipment Country</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeRouter('shipment_country', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Country</MenuItem>
                      {shipmentData.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={
                        newParam.get('sku_filter') === 'All'
                          ? 'Warehouse Discontinued Products'
                          : newParam.get('sku_filter')
                      }
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      onChange={(e) => {
                        onChangeRouter('sku_filter', e.target.value)
                      }}
                      IconComponent={() => null}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>SKU Discontinued Filter</span>
                        }
                        return selected
                      }}
                    >
                      <MenuItem value='All'>All</MenuItem>
                      {warehouseData.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('commerce_type')}
                      displayEmpty
                      InputLabelProps={{ shrink: false }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Commerce Type'
                        }
                        const menuItems = [
                          { value: 'ecomm', label: 'Ecomm' },
                          { value: 'false', label: 'Non Ecomm' }
                        ]

                        const selectedItem = menuItems.find((item) => item.value === selected)
                        return selectedItem ? selectedItem.label : 'Commerce Type'
                      }}
                      onChange={(e) => {
                        onChangeRouter('commerce_type', e.target.value)
                      }}
                      IconComponent={() => null}
                    >
                      <MenuItem value='ecomm'>Ecomm</MenuItem>
                      <MenuItem value='false'>Non Ecomm</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={handleExportClick}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <BiExport className='me-2' size='1.0rem' />
                    Export
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      navigate('/trends')
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* Sales Trends Alert Listing */}
          <div className='row mt-4'>
            <div className='col-md-12'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h5>Sales Trends Listing</h5>
                    <div className='d-flex justify-content-between align-items-center'>
                      <span className='fw-semibold me-2' style={{ fontSize: 20 }}>
                        Sort By :
                      </span>
                      <Grid item xs={12} sm={6} md={4} lg={1}>
                        <FormControl
                          fullWidth
                          sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                          size='small'
                          className='border-none'
                        >
                          <Select
                            displayEmpty
                            InputLabelProps={{ shrink: false }}
                            IconComponent={() => null}
                            value={newParam.get('sort_by') || 'SKU'}
                            onChange={(event) => {
                              onChangeRouter('sort_by', event.target.value)
                            }}
                          >
                            <MenuItem value='SKU'>SKU</MenuItem>
                            <MenuItem value='Design'>Design</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </div>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  <TrendsSalesTable
                    sortBy={newParam.get('sort_by') || 'SKU'}
                    newParam={newParam}
                    trendsData={trendsData?.data}
                    calcuateData={{ ...trendsData }}
                    saleTrendPerPage={50}
                    saleTrendPage={saleTrendPage}
                    setSaleTrendPage={setSaleTrendPage}
                    ref={tableRef}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Stock Alerts Filters */}
          <div className='common-card rounded-3 mt-4'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid>
                <h3 className='fw-bold advertisement-report'>
                  New Stock Alerts Reports <span></span>
                </h3>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Tooltip title={'Search Sku'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='Search Sku'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={newParam.get('alert_sku') || ''}
                        input={<CustomisedInput />}
                        onChange={(e) => {
                          onChangeRouter('alert_sku', e?.target?.value.trim())
                        }}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam?.get('stock_warehouse') || 'Cella IW (ETL) Logistik Center GmbH'}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Warehouse</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        onChangeRouter('stock_warehouse', e.target.value)
                      }}
                    >
                      {warehouseData?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      ExportNewReport()
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <BiExport className='me-2' size='1.2rem' />
                    Export
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      navigate('/trends')
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* Stock Alerts Listing */}
          <div className='row mt-4'>
            <div className='col-md-12 h-100'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h5>New Stock Alerts - Listing</h5>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  <PoReportTable
                    tableHeadCells={NewTrendReportHeadCells}
                    count={10}
                    ReportList={newStockReport?.data}
                    ReportLoading={newStockReportLoading}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Stock Alerts Filters */}
          <div className='common-card rounded-3 mt-4'>
            <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
              <Grid>
                <h3 className='fw-bold advertisement-report'>
                  Stock Alerts <span></span>
                </h3>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Tooltip title={'Search Sku'}>
                    <FormControl
                      fullWidth
                      sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                      size='small'
                      className='border-none'
                    >
                      <TextField
                        placeholder='Search Sku'
                        size='small'
                        variant='outlined'
                        InputLabelProps={{
                          shrink: false
                        }}
                        value={newParam.get('alert_sku') || ''}
                        input={<CustomisedInput />}
                        onChange={(e) => {
                          onChangeRouter('alert_sku', e?.target?.value.trim()),
                            setStockAlertPage(1),
                            setStockAlertData({ data: [], total_count: 0 })
                        }}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam?.get('stock_warehouse') || 'Cella IW (ETL) Logistik Center GmbH'}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Warehouse</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setStockAlertPage(1),
                          setStockAlertData({ data: [], total_count: 0 }),
                          onChangeRouter('stock_warehouse', e.target.value)
                      }}
                    >
                      {warehouseData?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('supplier')}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>All Supplier</span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setStockAlertPage(1),
                          setStockAlertData({ data: [], total_count: 0 }),
                          onChangeRouter('supplier', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All Supplier</MenuItem>
                      {supplierData.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                    size='small'
                    className='border-none'
                  >
                    <Select
                      value={newParam.get('sort_type')}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>SORT FILTER </span>
                        }
                        return selected
                      }}
                      IconComponent={() => null}
                      onChange={(e) => {
                        setStockAlertPage(1),
                          setStockAlertData({ data: [], total_count: 0 }),
                          onChangeRouter('sort_type', e.target.value)
                      }}
                    >
                      <MenuItem value=''>All </MenuItem>
                      <MenuItem value='Over Stock'>OVER STOCK </MenuItem>
                      <MenuItem value='Urgent Stock'>URGENT STOCK</MenuItem>
                      <MenuItem value='Plan Stock'>PLAN STOCK </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={newParam.get('sku_filter_alert')}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                // Use the mapping to display the selected value
                                                return filterOptions[selected] || "SKU Filter"
                                            }}
                                            IconComponent={() => null}
                                            onChange={(e) => { setStockAlertPage(1), setStockAlertData({ data: [], total_count: 0 }), onChangeRouter('sku_filter_alert', e.target.value) }}
                                        >
                                            {Object.entries(filterOptions).map(([value, label]) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      initilizeStockAlertList(1, 2500),
                        showToast('Please wait while we are fetching the Stocks Records', false)
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <BiExport className='me-2' size='1.2rem' />
                    Export
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      fetchWareHouseHangfire()
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <BiImport className='me-2' size='1.2rem' />
                    Import
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Button
                    className='rounded-3 fs-16'
                    variant='outlined'
                    onClick={() => {
                      navigate('/trends'), setStockAlertPage(1), setStockAlertData({ data: [], total_count: 0 })
                    }}
                    style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                  >
                    <GrPowerReset className='me-2' size='1.0rem' />
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* Stock Alerts Listing */}
          <div className='row mt-4'>
            <div className='col-md-12 h-100'>
              <div className='common-card rounded-3'>
                <div className='common-card-title'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h5>Stock Alerts - Listing</h5>
                  </div>
                </div>
                <div className='common-card-content border-0 mt-0 mb-0'>
                  <TrendsStockTable
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    setStockAlertData={setStockAlertData}
                    stockAlertData={stockAlertData?.data}
                    setStockAlertPage={setStockAlertPage}
                    stockAlertPage={stockAlertPage}
                    loading={alertLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalDialog
        open={openModal}
        fullWidth
        isDivider='true'
        title={
          <div>
            <h5 className='fw-semibold'>INFORMATION </h5>
            <span style={{ fontSize: 14, color: 'green' }}>
              Last Imported Date - {moment(stockAlertData?.data[0]?.updatedAt).format('DD MMM YYYY:hh:mm A')}
            </span>
          </div>
        }
        content={<p>Warehouse Data import initiated!! Kindly wait a few minutes , this will be updated shortly.</p>}
        handleClose={() => setOpenModal(false)}
      />
    </>
  )
}

export default Trends
