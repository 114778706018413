export const activityHeadCells = [
  {
    id: 'buyer',
    numeric: false,
    disablePadding: false,
    label: 'Activity',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: true,
    width: '20%'
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    isCentered: false,
    label: 'Location',
    disableSort: true,
    hiddenOnMobile: true,
    width: '25%'
  },
  {
    id: 'timeline',
    numeric: false,
    disablePadding: false,
    isCentered: false,
    label: 'Timeline',
    disableSort: true,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'summary',
    numeric: false,
    disablePadding: false,
    isCentered: true,
    label: 'Summary',
    disableSort: true,
    hiddenOnMobile: false,
    width: '45%'
  }
]
export const warehouseHeadCells = [
  {
    id: 'warehouseName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
    hiddenOnMobile: false,
    width: '20%'
  },
  {
    id: 'warehouseShortName',
    numeric: false,
    disablePadding: false,
    label: 'Alias',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'mobile',
    numeric: false,
    disablePadding: false,
    label: 'Mobile',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    disableSort: true,
    hiddenOnMobile: false,
    width: '20%'
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'Is Active',
    disableSort: true,
    hiddenOnMobile: false,
    width: '10%'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]
export const projectlistHeadCells = [
  {
    id: 'catalogue',
    numeric: false,
    disablePadding: false,
    label: 'Po Number',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },

  {
    id: 'supplier',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'shipTo',
    numeric: false,
    disablePadding: false,
    label: 'Ship To',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'poDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'poDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Due Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'shipVia',
    numeric: false,
    disablePadding: false,
    label: 'Ship Via',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },

  {
    id: 'checkbox',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]

export const catalogueHeadCells = [
  {
    id: 'catalogue',
    numeric: false,
    disablePadding: false,
    label: 'Po Number',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },

  {
    id: 'supplierName',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'shipTo',
    numeric: false,
    disablePadding: false,
    label: 'Ship To',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'poDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'poDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Due Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'poReadyDate',
    numeric: false,
    disablePadding: false,
    label: 'Po Ready Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'shipVia',
    numeric: false,
    disablePadding: false,
    label: 'Ship Via',
    disableSort: false,
    hiddenOnMobile: true,
    width: '9%'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    disableSort: false,
    hiddenOnMobile: true,
    width: '13%'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]
export const projectHeadCells = [
  {
    id: 'catalogue',
    numeric: false,
    disablePadding: false,
    label: 'Project Id',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },

  {
    id: 'loadDate',
    numeric: false,
    disablePadding: false,
    label: 'Load Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'arrivalDate',
    numeric: false,
    disablePadding: false,
    label: 'Arrival Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },
  {
    id: 'deliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Date',
    disableSort: false,
    hiddenOnMobile: true,
    width: '10%'
  },

  {
    id: 'bookingNumber',
    numeric: false,
    disablePadding: false,
    label: 'Booking Number',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'arrivalWarehouse',
    numeric: false,
    disablePadding: false,
    label: 'Arrival Warehouse',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'freightLine',
    numeric: false,
    disablePadding: false,
    label: 'Freight Line',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'container',
    numeric: false,
    disablePadding: false,
    label: 'Container',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'documents',
    numeric: false,
    disablePadding: false,
    label: 'Documents',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'projectStage',
    numeric: false,
    disablePadding: true,
    label: 'Stage',
    disableSort: true,
    hiddenOnMobile: true,
    width: '8%'
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    isCentered: false,
    disableSort: true,
    hiddenOnMobile: false,
    width: '14%'
  }
]

export const BulkImportHeardcells = [
  {
    id: 'Details',
    numeric: false,
    disablePadding: false,
    label: 'Details',
    disableSort: true,
    hiddenOnMobile: false,
    width: '30%'
  },
  {
    id: 'Time',
    numeric: false,
    disablePadding: false,
    label: 'Imported On',
    disableSort: false,
    hiddenOnMobile: false,
    width: '25%'
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
    hiddenOnMobile: false,
    width: '15%'
  },
  {
    id: 'Products',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    disableSort: true,
    hiddenOnMobile: false,
    width: '20%'
  }
]

export const paymentHeadCells = [
  {
    id: 'order',
    numeric: false,
    disablePadding: false,
    label: 'Order',
    disableSort: true,
    hiddenOnMobile: false,
    width: '25%'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    disableSort: true,
    hiddenOnMobile: true,
    width: '8%'
  },
  {
    id: 'plan',
    numeric: false,
    disablePadding: false,
    label: 'Plan',
    disableSort: false,
    hiddenOnMobile: true,
    width: '15%'
  },
  {
    id: 'invoice',
    numeric: false,
    disablePadding: false,
    label: 'Invoice',
    isCentered: true,
    disableSort: true,
    hiddenOnMobile: true,
    width: '12%',
    tooltip: true
  }
]
export const PurchaseReportHeadCells = [
  {
    id: 'PoNumber',
    numeric: false,
    label: 'PO Number'
  },
  {
    id: 'Sku',
    numeric: false,
    label: 'SKU'
  },
  {
    id: 'SupplierName',
    numeric: false,
    label: 'Vendor'
  },
  {
    id: 'ValidatedQuantity',
    numeric: true,
    label: 'Quantity'
  },
  {
    id: 'ReadyQuantity',
    numeric: true,
    label: 'Ready Quantity'
  },
  {
    id: 'DeliverdQuantity',
    numeric: true,
    label: 'Deliverd Quantity'
  },
  {
    id: 'PendingQuantity',
    numeric: true,
    label: 'Pending Quantity'
  },

  {
    id: 'ShipTo',
    numeric: false,
    label: 'Warehouse'
  }
]

export const ProductReportHeadCells = [
  {
    id: 'sku',
    numeric: false,
    label: 'SKU'
  },
  {
    id: 'PoNumber',
    numeric: false,
    label: 'PO Number'
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity'
  },
  {
    id: 'deliveryId',
    numeric: false,
    label: 'Delivery Id'
  },
  {
    id: 'delivery_warehouse',
    numeric: false,
    label: 'Warehouse'
  },
  {
    id: 'last_warehouse_date',
    numeric: false,
    label: 'Warehouse Date'
  },
  {
    id: 'warehouses',
    numeric: false,
    label: 'SKU Ages'
  }
]

export const StylePatternHeadCells = [
  {
    id: 'pattern_Style',
    numeric: false,
    label: 'Style/Pattern'
  },
  {
    id: 'total_qty_previous_year',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'total_untaxed_previous_year',
    numeric: false,
    label: 'Untaxed'
  },
  {
    id: 'contribution_qty_prev',
    numeric: false,
    label: '% Contribution (QTY)'
  },
  {
    id: 'contribution_untaxed_prev',
    numeric: false,
    label: '% Contribution (PRICE)'
  },
  {
    id: 'total_qty_current_year',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'total_untaxed_current_year',
    numeric: false,
    label: 'Untaxed'
  },
  {
    id: 'contribution_qty_current',
    numeric: false,
    label: '% Contribution (QTY)'
  },
  {
    id: 'contribution_untaxed_current',
    numeric: false,
    label: '% Contribution (PRICE)'
  },
  {
    id: 'change_qty',
    numeric: false,
    label: '% CHANGE (QTY)'
  },
  {
    id: 'change_untaxed',
    numeric: false,
    label: '% CHANGE (PRICE)'
  }
]

export const ColoursHeadCells = [
  {
    id: 'color',
    numeric: false,
    label: 'Colours'
  },
  {
    id: 'total_qty_previous_year',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'total_untaxed_previous_year',
    numeric: false,
    label: 'Untaxed'
  },
  {
    id: 'contribution_qty_prev',
    numeric: false,
    label: '% Contribution (QTY)'
  },
  {
    id: 'contribution_untaxed_prev',
    numeric: false,
    label: '% Contribution (PRICE)'
  },
  {
    id: 'total_qty_current_year',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'total_untaxed_current_year',
    numeric: false,
    label: 'Untaxed'
  },
  {
    id: 'contribution_qty_current',
    numeric: false,
    label: '% Contribution (QTY)'
  },
  {
    id: 'contribution_untaxed_current',
    numeric: false,
    label: '% Contribution (PRICE)'
  },
  {
    id: 'change_qty',
    numeric: false,
    label: '% CHANGE (QTY)'
  },
  {
    id: 'change_untaxed',
    numeric: false,
    label: '% CHANGE (PRICE)'
  }
]

export const SizePatternHeadCells = [
  {
    id: 'shipLength',
    numeric: false,
    label: 'Size'
  },
  {
    id: 'total_qty_previous_year',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'total_untaxed_previous_year',
    numeric: false,
    label: 'Untaxed'
  },
  {
    id: 'contribution_qty_prev',
    numeric: false,
    label: '% Contribution (QTY)'
  },
  {
    id: 'contribution_untaxed_prev',
    numeric: false,
    label: '% Contribution (PRICE)'
  },
  {
    id: 'total_qty_current_year',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'total_untaxed_current_year',
    numeric: false,
    label: 'Untaxed'
  },
  {
    id: 'contribution_qty_current',
    numeric: false,
    label: '% Contribution (QTY)'
  },
  {
    id: 'contribution_untaxed_current',
    numeric: false,
    label: '% Contribution (PRICE)'
  },
  {
    id: 'change_qty',
    numeric: false,
    label: '% CHANGE (QTY)'
  },
  {
    id: 'change_untaxed',
    numeric: false,
    label: '% CHANGE (PRICE)'
  },
  {
    id: 'average_order_previous',
    numeric: false,
    label: 'AOV PREVIOUS'
  },
  {
    id: 'average_order_current',
    numeric: false,
    label: 'AOV CURRENT'
  }
]

export const NewTrendReportHeadCells = [
  {
    id: 'sku',
    numeric: false,
    label: 'SKU'
  },
  // {
  //   id: 'sales_price',
  //   numeric: false,
  //   label: 'Sale Price'
  // },
  {
    id: 'totalQtyOrdered',
    numeric: false,
    label: 'Total Quantity'
  },
  {
    id: 'totalUntaxedTotal',
    numeric: false,
    label: 'Total Sales'
  },
  // {
  //   id: 'contribution',
  //   numeric: false,
  //   label: 'Contribution'
  // },
  // {
  //   id: 'cummulative',
  //   numeric: false,
  //   label: 'Cummulative'
  // },
  // {
  //   id: 'abc',
  //   numeric: false,
  //   label: 'ABC'
  // },
  {
    id: 'avg',
    numeric: false,
    label: 'AVG'
  },
  // {
  //   id: 'standard_deviation',
  //   numeric: false,
  //   label: 'SD'
  // },
  // {
  //   id: 'variance',
  //   numeric: false,
  //   label: 'Varience'
  // },
  // {
  //   id: 'hml',
  //   numeric: false,
  //   label: 'HML'
  // },
  {
    id: 'abchml',
    numeric: false,
    label: 'ABC&HML'
  },
  // {
  //   id: 'percentage',
  //   numeric: false,
  //   label: '%'
  // },
  // {
  //   id: 'normalization',
  //   numeric: false,
  //   label: 'Normalization'
  // },
  // {
  //   id: 'restock_point',
  //   numeric: false,
  //   label: 'Restock Point'
  // },
  // {
  //   id: 'warehouse_stock',
  //   numeric: false,
  //   label: 'WH Stock'
  // },
  // {
  //   id: 'po_quantity',
  //   numeric: false,
  //   label: 'IN PO'
  // },
  {
    id: 'intransit_quantity',
    numeric: false,
    label: 'IN TRANSIT'
  },
  {
    id: 'net_stocks',
    numeric: false,
    label: 'Net Stocks'
  },
  // {
  //   id: 'alert',
  //   numeric: false,
  //   label: 'Alert'
  // },
  // {
  //   id: 'lead_time',
  //   numeric: false,
  //   label: 'Lead Time'
  // },
  // {
  //   id: 'salt_rounds',
  //   numeric: false,
  //   label: 'SA*LT'
  // },
  {
    id: 'generate_po',
    numeric: false,
    label: 'GENERATE PO'
  }
]
