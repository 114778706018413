import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Skeleton, TableContainer } from '@mui/material'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { AverageOrderValue, calculatePercentageDifference } from 'shared/util/helper'

const CommonTableSku = (props) => {
  const {
    sortBy,
    rowsPerPage,
    page,
    loading,
    data,
    handleChangePage,
    lastMonth,
    lastYear,
    currentMonth,
    currentYear,
    selectedYear
  } = props
  const [totals, setTotals] = useState(null)
  const [sortConfig, setSortConfig] = useState({ type: 'qty_ordered_2023', order: 'asc' })
  const [allDataLoaded, setAllDataLoaded] = useState(false)
  const tableContainerRef = useRef(null)

  const SkeletonRow = ({ cols }) => (
    <TableRow>
      {Array.from({ length: cols }, (_, index) => (
        <TableCell key={index}>
          <Skeleton variant='text' />
        </TableCell>
      ))}
    </TableRow>
  )
  SkeletonRow.propTypes = {
    cols: PropTypes.number.isRequired
  }
  const aggregateData = (skuData) => {
    const aggregatedData = skuData.reduce((acc, item) => {
      const skuPrefix = item.sku.split('-')[0]
      if (!acc[skuPrefix]) {
        acc[skuPrefix] = {
          flat_image: item.flat_image,
          sku: skuPrefix,
          untaxed_2024: 0,
          qty_ordered_2024: 0,
          untaxed_2023: 0,
          qty_ordered_2023: 0
        }
      }
      acc[skuPrefix].untaxed_2024 += item.untaxed_2024 ?? 0
      acc[skuPrefix].qty_ordered_2024 += item.qty_ordered_2024 ?? 0
      acc[skuPrefix].untaxed_2023 += item.untaxed_2023 ?? 0
      acc[skuPrefix].qty_ordered_2023 += item.qty_ordered_2023 ?? 0
      return acc
    }, {})
    return Object.values(aggregatedData)
  }
  const aggregatedData = useMemo(() => {
    if (sortBy === 'Design') {
      return aggregateData(data || [])
    }
    return data || []
  }, [data, sortBy])

  const sortData = (skuData, type, order) => {
    return [...skuData].sort((a, b) => {
      const aValue = a[type] ?? 0
      const bValue = b[type] ?? 0
      return order === 'asc' ? aValue - bValue : bValue - aValue
    })
  }
  const sortedData = useMemo(
    () => sortData([...(aggregatedData || [])], sortConfig.type, sortConfig.order),
    [aggregatedData, sortConfig]
  )
  const paginatedData = useMemo(() => {
    const start = page * rowsPerPage
    const end = start + rowsPerPage
    return sortedData.slice(0, end)
  }, [sortedData, page, rowsPerPage])
  const loadMoreData = () => {
    if (sortedData.length > paginatedData.length) {
      handleChangePage('', page + 1)
    } else {
      setAllDataLoaded(true)
    }
  }
  const getIconStyle = (type, order) => ({
    color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
    cursor: 'pointer',
    marginLeft: '1px'
  })
  const handleSort = async (type) => {
    const container = tableContainerRef.current
    if (container) {
      await container.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
    setSortConfig({ type, order: newOrder })
  }
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
    if (scrollTop + clientHeight >= scrollHeight - 10 && !allDataLoaded) {
      loadMoreData()
    }
  }
  useEffect(() => {
    const container = tableContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll, true)
      return () => {
        container.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [allDataLoaded, page, sortedData])
  const calculateTotals = async (data) => {
    if (data?.length) {
      let totals = { untaxed_2024: 0, untaxed_2023: 0, qty_ordered_2023: 0, qty_ordered_2024: 0 }
      for (let item of data) {
        totals.untaxed_2024 += item?.untaxed_2024 ?? 0
        totals.untaxed_2023 += item?.untaxed_2023 ?? 0
        totals.qty_ordered_2023 += item?.qty_ordered_2023 ?? 0
        totals.qty_ordered_2024 += item?.qty_ordered_2024 ?? 0
      }
      return totals
    } else {
      return null
    }
  }
  useEffect(() => {
    const fetchTotals = async () => {
      if (data?.length) {
        try {
          const result = await calculateTotals(data)
          setTotals(result)
        } catch (error) {
          console.error('Error calculating totals:', error)
        }
      }
    }
    fetchTotals()
  }, [data])
  useEffect(() => {
    setAllDataLoaded(false)
    handleChangePage('', 0)
  }, [data])
  return (
    <>
      <TableContainer className='scroll-box' ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{sortBy === 'Design' ? 'Design' : 'SKU'}</strong>
              </TableCell>
              <TableCell>
                <strong>Image</strong>
              </TableCell>
              <TableCell colSpan={3} align='center'>
                <strong>Sales</strong>
              </TableCell>
              <TableCell colSpan={3} align='center'>
                <strong>Units Sold</strong>
              </TableCell>
              <TableCell colSpan={2} align='center'>
                <strong>Average Order Value</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <strong>{selectedYear === 'currentYear' ? lastMonth : lastYear}</strong>
              </TableCell>
              <TableCell>
                <strong>{selectedYear === 'currentYear' ? currentMonth : currentYear}</strong>
              </TableCell>
              <TableCell>
                <strong>Sales Variance</strong>
              </TableCell>
              <TableCell>
                <strong onClick={() => handleSort('qty_ordered_2023')}>
                  {selectedYear === 'currentYear' ? lastMonth : lastYear}
                  <FaArrowUpLong style={getIconStyle('qty_ordered_2023', 'asc')} />
                  <FaArrowDownLong style={getIconStyle('qty_ordered_2023', 'desc')} />
                </strong>
              </TableCell>
              <TableCell>
                <strong onClick={() => handleSort('qty_ordered_2024')}>
                  {selectedYear === 'currentYear' ? currentMonth : currentYear}
                  <FaArrowUpLong style={getIconStyle('qty_ordered_2024', 'asc')} />
                  <FaArrowDownLong style={getIconStyle('qty_ordered_2024', 'desc')} />
                </strong>
              </TableCell>
              <TableCell>
                <strong>Unit Variance</strong>
              </TableCell>
              <TableCell>
                <Tooltip title={`Average Order Value ${selectedYear === 'currentYear' ? lastMonth : lastYear}`}>
                  <strong>{selectedYear === 'currentYear' ? lastMonth : lastYear}</strong>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={`Average Order Value ${selectedYear === 'currentYear' ? currentMonth : currentYear}`}>
                  <strong>{selectedYear === 'currentYear' ? currentMonth : currentYear}</strong>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: rowsPerPage }, (_, index) => <SkeletonRow key={index} cols={10} />)
            ) : Array.isArray(paginatedData) && paginatedData.length ? (
              paginatedData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item?.sku ? item?.sku : '--'}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <img
                          src={
                            item?.flat_image
                              ? item?.flat_image
                              : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                          }
                          alt='Product'
                          width='200px'
                        />
                      }
                      arrow
                      placement='right'
                      PopperProps={{
                        style: { marginLeft: '10px' } // Adjust the left margin as needed
                      }}
                    >
                      <img
                        src={
                          item?.flat_image
                            ? item?.flat_image
                            : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                        }
                        alt='Product'
                        width='60px'
                        height='60px'
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>{item?.untaxed_2023 ? `€${item?.untaxed_2023.toFixed(2)}` : '--'}</TableCell>
                  <TableCell>{item?.untaxed_2024 ? `€${item?.untaxed_2024.toFixed(2)}` : '--'}</TableCell>
                  <TableCell
                    className={`${
                      calculatePercentageDifference(item?.untaxed_2024 ?? 0, item?.untaxed_2023 ?? 0) < 0
                        ? 'text-danger'
                        : 'text-success'
                    } fw-bold`}
                  >
                    {Math.abs(calculatePercentageDifference(item?.untaxed_2024 ?? 0, item?.untaxed_2023 ?? 0))
                      .toFixed(2)
                      .replace(/[.,]00$/, '')}{' '}
                    %
                  </TableCell>
                  <TableCell>{item?.qty_ordered_2023 ? item?.qty_ordered_2023 : '--'}</TableCell>
                  <TableCell>{item?.qty_ordered_2024 ? item?.qty_ordered_2024 : '--'}</TableCell>
                  <TableCell
                    className={`${
                      calculatePercentageDifference(item?.qty_ordered_2024 ?? 0, item?.qty_ordered_2023 ?? 0) < 0
                        ? 'text-danger'
                        : 'text-success'
                    } fw-bold`}
                  >
                    {Math.abs(calculatePercentageDifference(item?.qty_ordered_2024 ?? 0, item?.qty_ordered_2023 ?? 0))
                      .toFixed(2)
                      .replace(/[.,]00$/, '')}{' '}
                    %
                  </TableCell>
                  <TableCell>
                    {AverageOrderValue(item?.untaxed_2023 ?? 0, item?.qty_ordered_2023 ?? 0).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {AverageOrderValue(item?.untaxed_2024 ?? 0, item?.qty_ordered_2024 ?? 0).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} style={{ padding: 0 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 30,
                      marginBottom: 30
                    }}
                  >
                    <p className='text-center'>No Records Found... </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.length ? (
        <Table className='mt-3'>
          <TableHead className='ms-3'>
            <TableRow>
              <TableCell className='fw-bold fs-16'>Total </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <strong>{`€${totals?.untaxed_2023.toFixed(0)}` ?? '--'}</strong>
              </TableCell>
              <TableCell>
                <strong className='me-0 ms-0'>{`€${totals?.untaxed_2024.toFixed(0)}` ?? '--'}</strong>
              </TableCell>
              <TableCell
                className={`${
                  calculatePercentageDifference(totals?.untaxed_2024 ?? 0, totals?.untaxed_2023 ?? 0) < 0
                    ? 'text-danger'
                    : 'text-success'
                } fw-bold me-0 ms-0`}
              >
                {Math.abs(calculatePercentageDifference(totals?.untaxed_2024 ?? 0, totals?.untaxed_2023 ?? 0))
                  .toFixed(2)
                  .replace(/[.,]00$/, '')}{' '}
                %
              </TableCell>
              <TableCell>
                <strong>{totals?.qty_ordered_2023 ?? '--'}</strong>
              </TableCell>
              <TableCell>
                <strong>{totals?.qty_ordered_2024 ?? '--'}</strong>
              </TableCell>
              <TableCell
                className={`${
                  calculatePercentageDifference(totals?.qty_ordered_2024 ?? 0, totals?.qty_ordered_2023 ?? 0) < 0
                    ? 'text-danger'
                    : 'text-success'
                } fw-bold`}
              >
                {Math.abs(calculatePercentageDifference(totals?.qty_ordered_2024 ?? 0, totals?.qty_ordered_2023 ?? 0))
                  .toFixed(2)
                  .replace(/[.,]00$/, '')}{' '}
                %
              </TableCell>
              <TableCell className='fw-bold'>
                {AverageOrderValue(totals?.untaxed_2023 ?? 0, totals?.qty_ordered_2023 ?? 0).toFixed(2)}
              </TableCell>
              <TableCell className='fw-bold'>
                {AverageOrderValue(totals?.untaxed_2024 ?? 0, totals?.qty_ordered_2024 ?? 0).toFixed(2)}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      ) : (
        ''
      )}
    </>
  )
}

CommonTableSku.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  lastMonth: PropTypes.string.isRequired,
  lastYear: PropTypes.string.isRequired,
  currentMonth: PropTypes.string.isRequired,
  currentYear: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired
}

export default CommonTableSku
