import { UserContext } from 'context/AuthContext/UserContext'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import hangfireService from 'shared/services/hangfire-job.service'
import productService from 'shared/services/products.service'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { CalculateDates, exportJsonToCsvCustomRows, sortAlphabeticOrder } from 'shared/util/helper'

export const useTrendsHooks = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const newParam = new URLSearchParams(location.search)
  const { showToast } = useContext(UserContext)
  const [warehouseData, setWarehouseData] = useState([])
  const [shipmentData, setShipmentData] = useState([])
  const [supplierData, setSupplierData] = useState([])
  const [alertLoading, setAlertLoading] = useState(false)
  const [shopData, setShopData] = useState([])
  const [stockAlertPage, setStockAlertPage] = useState(1)
  const [saleTrendPage, setSaleTrendPage] = useState(0)
  const [sortConfig, setSortConfig] = useState({ type: 'sku', order: 'asc' })
  const [openModal, setOpenModal] = useState(false)
  const [stockAlertData, setStockAlertData] = useState({
    data: [],
    total_count: 0
  })
  const onChangeRouter = (key, value) => {
    const Param = new URLSearchParams(location.search)
    Param.set(key, value)
    if (!value) {
      Param.delete(key)
    }
    navigate({ search: Param.toString() })
  }
  const onChangeFilter = (name, type) => {
    let dateChanged = new Date(type)
    if (type) {
      newParam.set(name, dateChanged)
    } else {
      newParam.delete(name)
      newParam.delete('end_date')
      newParam.delete('sku_end_date')
    }
    navigate({ search: newParam.toString() })
  }
  useQuery(
    ['getWarehouse'],
    async () => {
      const response = await purchaseOrderService.getWarehouseData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'warehouse')
        const sortRes = response.map((item) => item.warehouse)
        setWarehouseData(sortRes)
      },
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  useQuery(
    ['getShipment'],
    async () => {
      const response = await purchaseOrderService.getShipmentData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'shipment_country')
        const sortRes = response.map((item) => item.shipment_country)
        setShipmentData(sortRes)
      },
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  useQuery(
    ['getShop'],
    async () => {
      const response = await purchaseOrderService.getShopData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'shop')
        const sortRes = response.map((item) => item.shop)
        setShopData(sortRes)
      },
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )
  useQuery(
    ['getsupplier'],
    async () => {
      const response = await purchaseOrderService.getSupplierData()
      return await response?.data?.supplier
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setSupplierData(response)
      },
      onError: (error) => {
        console.log(error?.response?.data?.message || 'Some error occurred')
      }
    }
  )

  const fetchWareHouseHangfire = async () => {
    try {
      const apiRes = await hangfireService.migrateWareHouseData()
      if (apiRes.status === 201) {
        setOpenModal(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const GetAllDiscontinuedProducts = async (FilterType) => {
    try {
      const response = await productService.GetAllDiscontinuedProducts(FilterType)
      const data = await response.data
      return data
    } catch (error) {
      console.log(error?.message || 'Some Error Occurred, Please Try Again')
    } finally {
    }
  }
  const initilizeSalesReportList = async (newParam) => {
    const dateFormet = await CalculateDates(newParam.get('period_date') || 'last8Weeks')
    let query = {}
    query.start_date =
      newParam.get('start_date') && newParam.get('end_date') ? newParam.get('start_date') : dateFormet.start_date
    query.end_date = newParam.get('end_date') || dateFormet.end_date
    query.period_date = newParam.get('period_date') || 'last8Weeks'
    if (newParam.get('warehouse')) query.warehouse = newParam.get('warehouse')
    if (newParam.get('sku')) query.search = newParam.get('sku')
    if (newParam.get('shops')) query.shop = newParam.get('shops')
    if (newParam.get('shipment_country')) query.shipment_country = newParam.get('shipment_country')
    // if (newParam.get('sku_filter')) query.sku_filter = newParam.get('sku_filter') || 'All'
    if (newParam.get('commerce_type')) query.commerce_type = newParam.get('commerce_type')
    const queryParams = new URLSearchParams(query)
    const apiRes = await purchaseOrderService.getSalesTrendlisting(queryParams.toString())
    const discontinuedProductData = await GetAllDiscontinuedProducts(newParam.get('sku_filter') || 'All')
    if (discontinuedProductData === 'No Data Available') {
      return {
        data: [],
        total_count: 0,
        unit_total_current: 0,
        unit_total_previous: 0,
        untaxed_total_current: 0,
        untaxed_total_previous: 0
      }
    }
    if (newParam.get('sku_filter') && discontinuedProductData.length > 0 && newParam.get('sku_filter') !== 'All') {
      const discontinuedSkus = discontinuedProductData.map((product) => product.sku)
      apiRes.data = {
        ...apiRes.data,
        data: apiRes.data?.data.filter((item) => discontinuedSkus.includes(item.sku))
      }
    }
    return apiRes.data
  }
  const { data: trendsData, isLoading: loading } = useQuery(
    [
      'salesReportList',
      newParam.get('warehouse'),
      newParam.get('sku'),
      newParam.get('shipment_country'),
      newParam.get('shops'),
      newParam.get('period_date'),
      newParam.get('start_date'),
      newParam.get('end_date'),
      newParam.get('sku_filter'),
      newParam.get('commerce_type')
    ],
    () => initilizeSalesReportList(newParam)
  )
  const initilizeStockAlertList = async (page, limit) => {
    if (stockAlertData.data.length >= stockAlertData.total_count && stockAlertData.total_count > 0) {
      return
    }
    setAlertLoading(true)
    try {
      let query = {}
      if (page) {
        query.pagination = page || 1
      }
      if (limit) {
        query.limit = limit
      }
      if (newParam.get('stock_warehouse')) {
        query.warehouse = newParam.get('stock_warehouse')
      } else {
        query.warehouse = 'Cella IW (ETL) Logistik Center GmbH'
      }
      query.type = sortConfig.type
      query.order = sortConfig.order
      if (newParam.get('supplier')) {
        query.supplier = newParam.get('supplier')
      }
      if (newParam.get('sku_filter_alert')) {
        query.sku_filter_alert = newParam.get('sku_filter_alert')
      }
      if (newParam.get('alert_sku')) {
        query.sku = newParam.get('alert_sku')
      }
      if (newParam.get('sort_type')) {
        query.sort_type = newParam.get('sort_type')
      }
      const queryParams = new URLSearchParams(query)
      const apiRes = await purchaseOrderService.getStockAlertlisting(queryParams.toString())
      let data = apiRes.data
      if (limit === 2500) {
        const rows = [
          [
            'SKU',
            'Vendor',
            'WareHouse',
            'Stocks',
            'Threshold Quantity',
            'MOH',
            'Days of Stock Remaining',
            'Average Sales',
            'Restock Point',
            'Status'
          ]
        ]
        if (Array.isArray(data?.data)) {
          data?.data.map((res) => {
            rows.push([
              res.sku,
              res.vendor,
              newParam.get('stock_warehouse') || 'Cella IW (ETL) Logistik Center GmbH',
              res?.total_stock,
              res?.threshold_quantity,
              res?.current_moh,
              res?.moh_days_stock_remaining,
              res?.final_avg_qty,
              res?.restock_point,
              res?.stock_status
            ])
          })
        }
        await exportJsonToCsvCustomRows(rows, 'Stocks')
        return
      }
      setStockAlertData((stockAlertData) => {
        let oldData = stockAlertData.data
        let newData = [...oldData, ...data?.data]
        return { ...stockAlertData, data: newData, total_count: data.total_count }
      })
    } catch (error) {
      setAlertLoading(false)
      console.log(error)
    } finally {
      setAlertLoading(false)
    }
  }
  const initilizeNewStockReportList = async (newParam) => {
    let query = {}
    if (newParam.get('stock_warehouse')) {
      query.warehouse = newParam.get('stock_warehouse')
    } else {
      query.warehouse = 'Cella IW (ETL) Logistik Center GmbH'
    }
    if (newParam.get('alert_sku')) {
      query.sku = newParam.get('alert_sku')
    }
    const queryParams = new URLSearchParams(query)
    const apiRes = await purchaseOrderService.getNewStockAlertlisting(queryParams.toString())
    return apiRes.data
  }
  const { data: newStockReport, isLoading: newStockReportLoading } = useQuery(
    ['newStockReport', newParam.get('stock_warehouse'), newParam.get('alert_sku')],
    () => initilizeNewStockReportList(newParam),
    { refetchOnWindowFocus: false }
  )

  const ExportNewReport = async () => {
    const rows = [
      [
        'SKU',
        'WareHouse',
        'Sale Price',
        'Total Quantity',
        'Total Untaxed',
        'Contribution',
        'Cummulative',
        'ABC',
        'AVG',
        'Standard Deviation',
        'Varience',
        'HML',
        'ABC&HML',
        '%',
        'Normalization',
        'Restock Point',
        'WH Stock',
        'IN PO',
        'IN TRANSIT',
        'Net Stocks',
        'Alert',
        'Lead Time',
        'SA*LT',
        'GENERATE PO'
      ]
    ]
    if (Array.isArray(newStockReport?.data)) {
      newStockReport?.data.map((res) => {
        rows.push([
          res.sku,
          newParam.get('stock_warehouse') || 'Cella IW (ETL) Logistik Center GmbH',
          res.sales_price,
          res?.totalQtyOrdered,
          res?.totalUntaxedTotal,
          res?.contribution,
          res?.cummulative,
          res?.abc,
          res?.avg,
          res?.standard_deviation,
          res.variance,
          res?.hml,
          res?.abchml,
          res?.percentage,
          res?.normalization,
          res?.restock_point,
          res?.warehouse_stock,
          res?.po_quantity,
          res?.intransit_quantity,
          res?.net_stocks,
          res?.alert,
          res?.lead_time,
          res?.salt_rounds,
          res.generate_po
        ])
      })
    }
    await exportJsonToCsvCustomRows(rows, 'NEWSTOCKSALERTREPORT')
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    initilizeStockAlertList(stockAlertPage)
  }, [
    sortConfig,
    stockAlertPage,
    newParam.get('alert_sku'),
    newParam.get('sort_type'),
    newParam.get('stock_warehouse'),
    newParam.get('supplier'),
    newParam.get('sku_filter_alert')
  ])

  return {
    newParam,
    navigate,
    openModal,
    setOpenModal,
    fetchWareHouseHangfire,
    warehouseData,
    shipmentData,
    shopData,
    supplierData,
    loading,
    trendsData,
    saleTrendPage,
    setSaleTrendPage,
    alertLoading,
    stockAlertPage,
    stockAlertData,
    setStockAlertData,
    setStockAlertPage,
    sortConfig,
    setSortConfig,
    onChangeRouter,
    onChangeFilter,
    showToast,
    initilizeStockAlertList,
    newStockReport,
    newStockReportLoading,
    ExportNewReport
  }
}
